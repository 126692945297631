import styled from "styled-components";
import computer from "../../assets/img/computer.png";
import diamond from "../../assets/img/diamond.png";
import arrow from "../../assets/img/arrow-hero.png";
import bgMbl from "../../assets/img/bg-hero-mbl.png";
import bg from "../../assets/img/bg-hero.png";
import peopleMbl from "../../assets/img/people-hero-mbl.png";
import people from "../../assets/img/people-hero.png";

export const Wrapper = styled.section`
  background: url(${bgMbl}) no-repeat;
  background-size: cover;
  padding: 6.25rem 0 1.875rem;
  position: relative;
  overflow: hidden;

  @media (min-width: 1024px) {
    background: url(${bg}) no-repeat;
    background-size: cover;
    padding: 7.25rem 0 2.5rem;
  }
`;

export const Container = styled.div`
  max-width: var(--max-width);
  width: 80%;
  margin: 0 auto;
  position: relative;

  @media (min-width: 360px) {
    width: 70%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h1 {
    font-size: 1.375rem;
    color: var(--text-color-secondary);
    text-transform: uppercase;
    max-width: 20rem;
    margin-bottom: 1rem;

    span {
      color: #f8c808;
    }
  }

  p {
    color: var(--text-color-secondary);
    max-width: 12.5rem;
    margin-bottom: 0.5rem;

    a {
      color: var(--text-color-secondary);
      text-decoration: underline;
    }

    .sign {
      font-weight: 700;
      font-size: 1.188rem;
      color: #f8c808;
    }

    .value {
      font-weight: 700;
      font-size: 2rem;
      color: #f8c808;
      line-height: 1;
    }

    &.highlight {
      padding: 0.625rem 0.875rem;
      font-size: 1rem;
      font-weight: 700;
      color: #f8c808;
      background: rgba(10, 30, 50, 0.3);
      box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(2px);
      border-radius: 10px;
    }
  }

  .privacy {
    font-size: 0.5rem;
    max-width: 18.313rem;
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    gap: 1.563rem;
    h1 {
      max-width: 21.875rem;
    }

    p {
      max-width: 18.75rem;
      margin-bottom: 0;

      .sign {
        font-size: 1.875rem;
      }

      .value {
        font-size: 3.125rem;
      }
    }

    .privacy {
      max-width: 18.75rem;
      font-size: 0.75rem;
    }
  }

  @media (min-width: 1024px) {
    gap: 1.563rem;
    h1 {
      max-width: 33rem;
      font-size: 2.375rem;
      margin-bottom: 0.5rem;
    }

    p {
      max-width: 34.375rem;
      font-size: 1.375rem;
      margin-bottom: 0.8rem;

      &.highlight {
        padding: 0.875rem 1rem;
        font-size: 1.375rem;
      }
    }

    .privacy {
      max-width: 34.375rem;
      font-size: 0.75rem;
      margin-bottom: 0;
    }
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  li {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    color: var(--text-color-secondary);

    svg {
      width: 30px;
      height: 30px;
    }

    h3 {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 1rem;
    }

    p {
      font-size: 1rem;
      max-width: 15rem;
    }
  }

  @media (min-width: 400px) {
    li {
      p {
        max-width: 18rem;
      }
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    max-width: 42.5rem;
    flex-wrap: wrap;
    column-gap: 4rem;
    row-gap: 1.5rem;

    li {
      max-width: 16rem;
      gap: 0.7rem;
    }
  }
`;

export const Offer = styled.img`
  object-fit: contain;
  position: absolute;
  right: 400px;
  top: 140px;

  @media (min-width: 1700px) {
    right: 600px;
  }
`;

export const Computer = styled.div`
  background: url(${computer}) no-repeat;
  background-size: contain;
  width: 240px;
  height: 282px;
  position: absolute;
  bottom: -60px;
  left: 30px;
`;

export const Diamond = styled.div`
  background: url(${diamond}) no-repeat;
  background-size: contain;
  width: 92px;
  height: 62px;
  position: absolute;
  bottom: 50px;
  right: 700px;
`;

export const Arrow = styled.div`
  background: url(${arrow}) no-repeat;
  background-size: contain;
  width: 253px;
  height: 45px;
  position: absolute;
  left: -270px;
  top: 5px;
`;

export const BgPeople = styled.div`
  background: url(${peopleMbl}) no-repeat;
  background-size: contain;
  width: 204px;
  height: 443px;
  position: absolute;
  right: -100px;
  top: 230px;

  @media (min-width: 400px) {
    right: -60px;
  }

  @media (min-width: 450px) {
    right: -30px;
  }

  @media (min-width: 768px) {
    background: url(${people}) no-repeat;
    background-size: contain;
    width: 449px;
    height: 641px;
    right: -80px;
  }

  @media (min-width: 1024px) {
    top: 90px;
    right: -95px;
  }

  @media (min-width: 1200px) {
    top: 120px;
    right: 0;
  }

  @media (min-width: 1350px) {
    width: 649px;
    height: 841px;
    top: 130px;
    right: -80px;
  }
  @media (min-width: 1600px) {
    right: 100px;
  }
  @media (min-width: 1920px) {
    right: 250px;
  }
`;

export const StyledButton = styled.a`
  background: var(--red);
  color: var(--text-color-secondary);
  width: 100%;
  height: 3.125rem;
  max-width: 18.313rem;
  padding: 1rem 0;
  border-radius: 140px;
  font-family: "JetBrains Mono", sans-serif;
  font-weight: 700;
  font-size: 0.775rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.3s ease-in-out;
  z-index: 2;
  box-shadow: inset 0 2px 2px rgba(255, 255, 255, 0.5);
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }

  @media (min-width: 350px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.25rem;
    height: 3.75rem;
    max-width: 33.938rem;
  }
`;
