import { StyledContainer } from "./styles";

interface ContainerProps {
  children: React.ReactNode;
  paddingTopOnly?: boolean;
}

export default function Container({
  children,
  paddingTopOnly = false,
}: ContainerProps) {
  return (
    <StyledContainer paddingTopOnly={paddingTopOnly}>
      {children}
    </StyledContainer>
  );
}
