import styled from "styled-components";
import bg from "../../assets/img/popup-bg.png";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  padding: 2.5rem 2rem;
  max-width: 50rem;

  .close {
    background: none;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  img {
    object-fit: contain;
  }

  @media (min-width: 768px) {
    .close {
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  @media (min-width: 1024px) {
    padding: 3rem 2rem;
    gap: 2rem;
    background: url(${bg}) no-repeat;
    background-size: cover;
    background-position: +10%;
  }
`;

export const Content = styled.div`
  strong {
    font-family: "JetBrains Mono", sans-serif;
    font-size: 1.313rem;
    color: #f8c808;
    text-transform: uppercase;
    display: block;
    max-width: 8.438rem;
  }

  p {
    font-size: 0.75rem;
    color: #f8c808;
    margin: 1rem 0;
    max-width: 34.125rem;
  }

  button {
    font-size: 0.688rem;
    margin-top: 0.5rem;
    padding: 0rem 2rem;
    height: 2.875rem;
  }

  input,
  select {
    height: 2.625rem;
    font-size: 0.75rem;

    &::-webkit-input-placeholder {
      font-size: 0.75rem;
    }
  }

  select {
    padding: 0 1rem;
  }

  @media (min-width: 1024px) {
    gap: 1.5rem;
    button {
      svg {
        width: 24px;
        height: 24px;
      }
    }
    strong {
      font-size: 2rem;
      max-width: none;
    }
    p {
      font-size: 0.875rem;
    }
    .terms {
      text-align: center;
    }
  }
`;

export const DuckContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;
