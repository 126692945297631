import { useMediaQuery } from "react-responsive";
import Container from "../Container";
import Title from "../Title";
import { ReactComponent as Warning } from "../../assets/img/warning.svg";
import { ReactComponent as Verify } from "../../assets/img/verify.svg";
import circle1 from "../../assets/img/comparative-banner-circle-1.png";
import circle2 from "../../assets/img/comparative-banner-circle-2.png";
import circle3 from "../../assets/img/comparative-banner-circle-3.png";

import {
  Background,
  Banner,
  Card,
  Content,
  Frame,
  Hand,
  RedArrow,
  Target,
  TargetChecked,
  YellowArrow,
} from "./styles";

export default function Comparative() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  return (
    <Background>
      <Container>
        <Title color="var(--text-color-secondary)">
          COMPARATIVO COM DIFERENTES INSTITUIÇÕES:
        </Title>
        <Content>
          <Card>
            <h3>
              {isDesktop ? (
                <>
                  <Warning />
                  <div>
                    CURSO RÁPIDO
                    <br />
                    <span>(Online)</span>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <Warning />
                  CURSO RÁPIDO <span> (Online)</span>
                </>
              )}
            </h3>
            <ul>
              <li>Duração: 1 - 4 semanas</li>
              <li>Valor: menos de R$1.000</li>
              <li>Apenas uma linguagem ou ferramenta de maneira superficial</li>
              <li>Videoaulas gravadas sem nenhum acompanhamento</li>
              <li>Zero suporte para colocação no mercado</li>
            </ul>
          </Card>

          <Card>
            <h3>
              <Verify /> KENZIE
            </h3>
            <ul>
              <li>Duração: 12 meses</li>
              <li>R$ 17.600</li>
              <li>Múltiplas tecnologias e ferramentas</li>
              <li>Aprendizado em profundidade na prática</li>
              <li>98% de taxa de empregabilidade</li>
            </ul>
            <Hand />
          </Card>

          <Card>
            <h3>
              <Warning /> UNIVERSIDADE
            </h3>
            <ul>
              <li>Duração: 4 a 5 anos</li>
              <li>Valor: de R$400 a R$1000 por mês</li>
              <li>Conhecimento teórico amplo, mas sem vivência na prática</li>
              <li>Aprendizado baseado em professores e aulas tradicionais</li>
              <li>Sem foco em empregabilidade</li>
            </ul>
          </Card>
        </Content>
        <Banner>
          <p>
            <strong>
              em apenas dois anos, já ajudamos centenas de alunos a acelerarem
              sua entrada no mercado de tecnologia!
            </strong>
          </p>

          <div>
            <img
              src={circle1}
              alt="Mais de 2000 alunos matriculados"
              width={70}
              height={70}
            />
            <img
              src={circle2}
              alt="Mais de 200 empresas parceiras"
              width={70}
              height={70}
            />
            <img
              src={circle3}
              alt="98% dos alunos empregados"
              width={70}
              height={70}
            />
          </div>
        </Banner>
      </Container>
      <Frame />
      <Target />
      {!isDesktop && <Target />}
      {isDesktop && (
        <>
          <RedArrow />
          <YellowArrow />
          <TargetChecked />
        </>
      )}
    </Background>
  );
}
