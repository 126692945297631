import styled from "styled-components";
import bg from "../../assets/img/bg-investiment-conditions.png";
import specialConditions from "../../assets/img/special-conditions.png";
import offer5 from "../../assets/img/offer-red-5.png";
import offer10 from "../../assets/img/offer-red.png";
import coin from "../../assets/img/investiment-coin.png";
import limitedTime from "../../assets/img/limited-time.png";

export const Background = styled.div`
  position: relative;
  background: var(--brand-color);

  @media (min-width: 1024px) {
    background: var(--brand-color) url(${bg}) no-repeat;
    background-size: cover;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.875rem 0;

  .description {
    color: var(--text-color-secondary);
    margin-bottom: 1rem;
  }

  @media (min-width: 1024px) {
    padding: 2.5rem 0;

    h2 {
      align-self: center;
      font-size: 2.5rem;
    }

    .description {
      text-align: center;
      font-size: 1.25rem;
      max-width: 85%;
      margin: 0 auto 4rem;
    }
  }

  @media (min-width: 1350px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;

  p {
    color: var(--text-color-secondary);
    max-width: 70%;
  }

  @media (min-width: 768px) {
    p {
      max-width: 80%;
    }
  }

  @media (min-width: 1024px) {
    gap: 2.75rem;
    max-width: 20rem;

    p {
      font-size: 1.25rem;
    }
  }

  @media (min-width: 1150px) {
    max-width: 24.688rem;
  }

  @media (min-width: 1350px) {
    min-width: 27.688rem;
  }
`;

export const Announcement = styled.div`
  position: relative;
  h3 {
    font-weight: 800;
    font-size: 1.125rem;
    color: #f8c808;
    border-radius: 100px;
    width: fit-content;
    text-transform: uppercase;
    margin-bottom: 0.625rem;
    margin-left: -20px;
  }

  p {
    max-width: none;

    .sign {
      font-size: 0.75rem;
      font-weight: 700;

      &.big {
        font-size: 1.25rem;
        color: #f8c808;
      }
    }

    .before {
      font-size: 1.688rem;
      text-decoration: line-through;

      &:nth-child(2) {
        font-size: 1.25rem;
      }
    }

    .after {
      font-size: 2.5rem;
      font-weight: 700;
      color: #f8c808;
    }

    .installment {
      font-size: 0.865rem;
    }

    &.italic {
      font-size: 0.75rem;
      font-style: italic;
    }

    + p {
      margin-top: 0.438rem;
    }
  }
  @media (min-width: 1024px) {
    h3 {
      font-size: 1.5rem;
      margin-bottom: 1.25rem;
      margin-left: 0;
    }

    p {
      font-size: 1.25rem;

      &.italic {
        font-size: 0.875rem;
        margin-top: 1rem;
      }
    }
  }
`;

export const Financing = styled.div`
  width: fit-content;
  margin: 1rem auto;

  img {
    object-fit: contain;
  }
`;

export const SpecialConditions = styled.div`
  background: url(${specialConditions}) no-repeat;
  background-size: contain;
  width: 1194px;
  height: 44px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  &:nth-child(2) {
    top: auto;
    bottom: 0;
  }
`;

export const Offer = styled.div`
  background: url(${offer5}) no-repeat;
  background-size: contain;
  width: 74px;
  height: 83px;
  position: absolute;
  bottom: 0;
  right: -20px;

  @media (min-width: 500px) {
    right: 80px;
  }
  @media (min-width: 768px) {
    right: 220px;
  }
  @media (min-width: 1024px) {
    width: 117px;
    height: 88px;
    right: 100px;
    bottom: -20px;
    right: -20px;
  }

  @media (min-width: 1400px) {
    right: 65px;
  }
`;

export const Offer10 = styled(Offer)`
  background: url(${offer10}) no-repeat;
  background-size: contain;
  width: 72px;
  height: 72px;
  bottom: 0;
  right: -20px;

  @media (min-width: 500px) {
    right: 80px;
  }
  @media (min-width: 768px) {
    right: 220px;
  }

  @media (min-width: 1024px) {
    width: 88px;
    height: 88px;
    bottom: -20px;
    right: -20px;
  }

  @media (min-width: 1400px) {
    width: 116px;
    height: 116px;
    right: 45px;
  }
`;

export const Coin = styled.div`
  background: url(${coin}) no-repeat;
  background-size: contain;
  width: 71px;
  height: 140px;
  position: absolute;
  top: 45%;
  right: -30px;

  @media (min-width: 1024px) {
    width: 122px;
    height: 238px;
    right: -45px;
    top: 25%;
  }
`;

export const LimitedTime = styled.div`
  background: url(${limitedTime}) no-repeat;
  background-size: contain;
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 90px;
  right: -20px;

  @media (min-width: 420px) {
    bottom: 70px;
  }

  @media (min-width: 500px) {
    right: 20px;
  }
  @media (min-width: 768px) {
    right: 212px;
    bottom: 50px;
  }
  @media (min-width: 1024px) {
    width: 88px;
    height: 88px;
    bottom: 70px;
    right: -20px;
  }

  @media (min-width: 1400px) {
    right: 65px;
  }
`;

export const FormTitle = styled.h3`
  font-size: 1.375rem;
  font-weight: 800;
  color: #f8c808;
  text-transform: uppercase;
  max-width: 22.813rem;
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    font-size: 1.5rem;
  }
`;

export const Experience = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;

  svg {
    display: block;
    height: 40px;
  }
  p {
    font-weight: 700;
    font-size: 1rem;
    color: var(--text-color-secondary);
  }

  @media (min-width: 768px) {
    margin: 0 auto;
    align-items: center;
    svg {
      height: 72px;
      width: 72px;
    }
    p {
      font-size: 1.125rem;
      max-width: 46.875rem;
    }
  }

  @media (min-width: 1024px) {
    margin: 0;
  }
`;

export const FinancingWorks = styled.div`
  margin-bottom: 1.5rem;
  h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.375rem;
    font-weight: 700;
    color: #f8c808;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.75rem;
    margin: 1rem 0;
    li {
      background: #fff;
      box-shadow: 0.466348px 1.86539px 2.33174px rgba(0, 0, 0, 0.25),
        inset 0px 0.466348px 2.33174px 0.466348px rgba(255, 255, 255, 0.15);
      border-radius: 7px;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      font-size: 0.625rem;
      padding: 0.625rem;
      max-width: 7.313rem;

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  @media (min-width: 1024px) {
    margin: 1.5rem 0;
    ul {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
      gap: 1rem;

      li {
        padding: 1.5rem 1.575rem;
        font-size: 1rem;
        gap: 1.25rem;
        width: 15.625rem;
        max-width: 15.625rem;
        flex-grow: 1;
        line-height: 24px;

        svg {
          height: 44px;
          width: 44px;
        }
      }
    }
  }
`;
