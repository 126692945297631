import { useLocation } from "react-router-dom";

import { StyledLink } from "./styles";

interface Props {
  children: React.ReactNode;
}

export default function Cta({ children }: Props) {
  const { search, pathname } = useLocation();

  return (
    <StyledLink
      href={
        pathname === "/influ"
          ? `https://inscricao.kenzie.com.br/influ${search}`
          : `https://inscricao.kenzie.com.br${search}`
      }
      rel="noreferrer"
    >
      {children}
    </StyledLink>
  );
}
