import { MdExpandMore } from "react-icons/md";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import Title from "../Title";

import { Container, Content } from "./styles";

export default function FAQ() {
  return (
    <Container>
      <Title color="var(--brand-color)">Perguntas frequentes</Title>
      <Content>
        <Accordion>
          <AccordionSummary expandIcon={<MdExpandMore />}>
            <h3>
              <span>01.</span> Qual a duração do curso de programação da Kenzie?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              12 meses, sendo 6 meses iniciais de Front End e 6 meses finais de
              Back End.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<MdExpandMore />}>
            <h3>
              <span>02.</span> Preciso saber inglês?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Não exigimos um segundo idioma. Todos os materiais usados durante
              o curso são em português ou traduzidos.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<MdExpandMore />}>
            <h3>
              <span>03.</span> Quem é a Kenzie Academy? Quando ela foi fundada?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Somos uma escola de programação web, com sede nos EUA e
              metodologia do Vale do Silício para formação de desenvolvedores
              Full Stack em apenas 12 meses, com taxa de 98% de empregabilidade.
              Nossas aulas são ao vivo, com suporte em tempo real, e além da
              programação, nosso curso contempla também metodologia ágil e soft
              skills, além de uma forte preparação para entrevistas e um núcleo
              dedicado de empregabilidade.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<MdExpandMore />}>
            <h3>
              <span>04.</span> Quais os requisitos para ser aluno Kenzie?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              É preciso ter disponibilidade de horário das 9h às 15h (horário de
              Brasília), de segunda a sexta e dedicação adicional de pelo menos
              10 horas semanais pelo período de 12 meses. Nossos alunos devem
              possuir 18 anos ou mais, ter o ensino médio completo e um
              computador com acesso à Internet.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<MdExpandMore />}>
            <h3>
              <span>05.</span> Ganho um certificado?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Sim. Nossa formação conta com certificado de curso livre que
              comprova o conhecimento “Full Stack” em programação.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<MdExpandMore />}>
            <h3>
              <span>06.</span> Vocês me ajudam a conseguir um emprego?
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Contamos com uma rede de mais de 200 parceiros para ajudar na sua
              rápida contratação e colocação no mercado de trabalho. Esse
              parceiros são empresas e startups de diferentes tamanhos,
              segmentos e localizadas pelo Brasil inteiro. Além disso, daremos
              todo o suporte, através do nosso time, para você se preparar para
              os testes (hard skills) e entrevistas (soft skills).
            </p>
          </AccordionDetails>
        </Accordion>
      </Content>
    </Container>
  );
}
