import { useMediaQuery } from "react-responsive";
import TestimonialCard from "../TestimonialCard";
import Container from "../Container";
import Title from "../Title";
import {
  testimonialsC1,
  testimonialsC2,
  testimonialsC3,
  testimonialsC4,
} from "../../utils/testimonials";

import {
  Background,
  Megaphone,
  MegaphoneBlur,
  TestimonialColumn,
  Testimonials,
  TestimonialsContent,
} from "./styles";

export default function Testimonial() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  return (
    <Background>
      <Container>
        <TestimonialsContent>
          <Title>
            {isDesktop
              ? "Depoimentos de quem se formou com a gente:"
              : "Depoimentos:"}
          </Title>
          <Testimonials>
            <TestimonialColumn>
              {testimonialsC1.map((t) => (
                <TestimonialCard student={t} key={t.name} />
              ))}
            </TestimonialColumn>
            <TestimonialColumn className="column-display">
              {testimonialsC2.map((t) => (
                <TestimonialCard student={t} key={t.name} />
              ))}
            </TestimonialColumn>
            <TestimonialColumn className="column-display">
              {testimonialsC3.map((t) => (
                <TestimonialCard student={t} key={t.name} />
              ))}
            </TestimonialColumn>
            <TestimonialColumn className="column-display">
              {testimonialsC4.map((t) => (
                <TestimonialCard student={t} key={t.name} />
              ))}
            </TestimonialColumn>
          </Testimonials>
          {isDesktop && <MegaphoneBlur />}
        </TestimonialsContent>
      </Container>
      {!isDesktop && <MegaphoneBlur />}
      <Megaphone />
    </Background>
  );
}
