import styled from "styled-components";

export const StyledLink = styled.a`
  background: var(--red);
  color: var(--text-color-secondary);
  width: 100%;
  height: 3.125rem;
  max-width: 18.313rem;
  padding: 1rem 0;
  border-radius: 140px;
  font-family: "JetBrains Mono", sans-serif;
  font-weight: 700;
  font-size: 0.775rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.3s ease-in-out;
  z-index: 2;
  box-shadow: inset 0 2px 2px rgba(255, 255, 255, 0.5);

  &:hover {
    filter: brightness(0.8);
  }

  @media (min-width: 350px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.25rem;
    height: 3.75rem;
    max-width: 33.938rem;
  }
`;
