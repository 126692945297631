import { useMediaQuery } from "react-responsive";
import Container from "../Container";
import Title from "../Title";
import { items } from "./items";

import { Background, Content, Person, Wrapper } from "./styles";

export default function IndicatedCourse() {
  const isMobile = useMediaQuery({
    query: "(min-width: 400px)",
  });

  const isDesktop = useMediaQuery({
    query: "(min-width: 1700px)",
  });
  return (
    <Background>
      <Container>
        <Wrapper>
          <Title color="var(--brand-color)">
            Este curso é indicado para quem quer:
          </Title>
          <Content>
            {items.map((item) => (
              <li key={item.text}>
                <item.icon />
                <p>{item.text}</p>
              </li>
            ))}
          </Content>
          {isDesktop && <Person />}
        </Wrapper>
      </Container>

      {isMobile && !isDesktop && <Person />}
    </Background>
  );
}
