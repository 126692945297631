import ifood from "../../assets/img/partners/ifood.png";
import itflex from "../../assets/img/partners/itflex.png";
import hunters from "../../assets/img/partners/99hunters.png";
import boticario from "../../assets/img/partners/boticario.png";
import creditas from "../../assets/img/partners/creditas.png";
import james from "../../assets/img/partners/james.png";
import letras from "../../assets/img/partners/letras.png";
import madeiraMadeira from "../../assets/img/partners/madeiramadeira.png";
import picpay from "../../assets/img/partners/picpay.png";
import meliuz from "../../assets/img/partners/meliuz.png";
import paranaBanco from "../../assets/img/partners/parana-banco.png";
import zumer from "../../assets/img/partners/zumer.png";
import zallpy from "../../assets/img/partners/zallpy.png";
import acras from "../../assets/img/partners/acras.png";
import weexPass from "../../assets/img/partners/weex-pass.png";
import xgen from "../../assets/img/partners/xgen.png";
import vitto from "../../assets/img/partners/vitto.png";
import vulpi from "../../assets/img/partners/vulpi.png";
import stefaniniGroup from "../../assets/img/partners/stefanini-group.png";
import bancoBari from "../../assets/img/partners/banco-bari.png";
import bemolDigital from "../../assets/img/partners/bemol-digital.png";
import bycoders from "../../assets/img/partners/bycoders.png";
import provi from "../../assets/img/partners/provi.png";
import expressoLabs from "../../assets/img/partners/expresso-labs.png";
import grupoDigi from "../../assets/img/partners/grupo-digi.png";
import geekHunter from "../../assets/img/partners/geek-hunter.png";
import originalIo from "../../assets/img/partners/original-io.png";
import pixlog from "../../assets/img/partners/pixlog.png";
import qultureRocks from "../../assets/img/partners/qulture-rocks.png";
import remessaOnline from "../../assets/img/partners/remessa-online.png";
import revelo from "../../assets/img/partners/revelo.png";
import trakto from "../../assets/img/partners/trakto.png";

export const partnersList = [
  {
    name: "Ifood",
    img: ifood,
  },

  {
    name: "Grupo Boticário",
    img: boticario,
  },
  {
    name: "PicPay",
    img: picpay,
  },
  {
    name: "Meliuz",
    img: meliuz,
  },
  {
    name: "Madeira Madeira",
    img: madeiraMadeira,
  },
  {
    name: "James",
    img: james,
  },
  {
    name: "Creditas",
    img: creditas,
  },
  {
    name: "Letras",
    img: letras,
  },
  {
    name: "99Hunters",
    img: hunters,
  },

  {
    name: "Paraná Banco",
    img: paranaBanco,
  },
  {
    name: "Revelo",
    img: revelo,
  },
  {
    name: "Banco Bari",
    img: bancoBari,
  },
  {
    name: "Weex Pass",
    img: weexPass,
  },
  {
    name: "Provi",
    img: provi,
  },
  {
    name: "Stefanini Group",
    img: stefaniniGroup,
  },
  {
    name: "Bycoders",
    img: bycoders,
  },
  {
    name: "Geek Hunter",
    img: geekHunter,
  },
  {
    name: "Grupo Digi",
    img: grupoDigi,
  },
  {
    name: "itFlex",
    img: itflex,
  },
  {
    name: "Qulture Rocks",
    img: qultureRocks,
  },
  {
    name: "Expresso Labs",
    img: expressoLabs,
  },
  {
    name: "Bemol Digital",
    img: bemolDigital,
  },
  {
    name: "Zumer",
    img: zumer,
  },
  {
    name: "Xgen",
    img: xgen,
  },
  {
    name: "Original io",
    img: originalIo,
  },
  {
    name: "Vulpi",
    img: vulpi,
  },
  {
    name: "Vitto",
    img: vitto,
  },
  {
    name: "Remessa Online",
    img: remessaOnline,
  },
  {
    name: "Zallpy",
    img: zallpy,
  },

  {
    name: "Pixlog",
    img: pixlog,
  },
  {
    name: "Trakto",
    img: trakto,
  },
  {
    name: "Acras",
    img: acras,
  },
];
