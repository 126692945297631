import styled from "styled-components";
import playIcon from "../../assets/img/play-icon.svg";
import { css } from "styled-components";

interface Props {
  student: {
    name: string;
    genre: string;
    role: string;
    company?: string;
    testimonial?: string[];
    image?: string;
    video?: string;
    videoId?: number;
    videoHeight?: number;
  };
}

interface TitleProps extends Props {
  active: boolean;
}

export const Container = styled.article<Props>`
  width: 100%;
  height: 100%;
  background-color: var(--text-color-secondary);
  color: var(--text-color);
  font-size: 0.75rem;
  border: 1px solid var(--text-color-secondary);
  box-shadow: 0px 2px 4px 3px rgb(0 0 0 / 20%);
  border-radius: 20px;
  padding: ${({ student }) => (student?.video ? "0" : "1rem 1.5rem")};
  flex-direction: column;
  position: relative;
  ${({ student }) =>
    student?.video &&
    css`
      overflow: hidden;
    `}
  @media (min-width: 1024px) {
    padding: ${({ student }) => (student?.video ? "0" : "1rem 1rem")};
    height: ${({ student }) =>
      student.videoHeight ? `${student.videoHeight}rem` : "100%"};
  }
  .yt-lite {
    height: 100%;
  }
  .lty-playbtn {
    position: absolute;
    bottom: 5px;
    left: 15px;
    cursor: pointer;
    background-color: transparent;
    background-image: url(${playIcon});
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
    background-size: contain;
  }
  .yt-lite:hover .lty-playbtn,
  .lty-playbtn:hover {
    background-color: transparent;
  }
  .lty-playbtn::before {
    border-color: none;
    border-style: none;
    border-width: none;
  }
  .yt-lite > .lty-playbtn,
  .yt-lite > .lty-playbtn:before {
    top: 75%;
    left: 20px;
    transform: none;
    @media (min-width: 650px) and (max-width: 1023px) {
      ${({ student }) =>
        student.name === "Julia Sperandio" &&
        css`
          top: 85%;
        `}
    }
  }
  @media (min-width: 1200px) {
    padding: ${({ student }) => (student?.video ? "0" : "1.5rem 1.5rem")};
  }
`;

export const TestimonialHeader = styled.div`
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  img {
    margin-right: 1rem;
    object-fit: contain;
  }

  @media (min-width: 1024px) {
    font-size: 0.6rem;
    line-height: 1rem;
  }

  @media (min-width: 1400px) {
    font-size: 0.75rem;
    line-height: 1.2rem;
  }
`;

export const TestimonialContent = styled.p`
  font-size: 0.75rem;

  + p {
    margin-top: 0.5rem;
  }

  @media (min-width: 1024px) {
    font-size: 0.65rem;
  }
  @media (min-width: 1415px) {
    font-size: 0.75rem;
  }
  @media (min-width: 1550px) {
    font-size: 0.81rem;
  }
`;

export const TitleContent = styled.div<TitleProps>`
  ${({ student }) =>
    student.video &&
    css`
      position: absolute;
      bottom: 5px;
      right: 15px;
    `}
  z-index: 1;
  display: ${({ active }) => !active && "none"};
  h3 {
    margin-bottom: 0.25rem;
    color: ${({ student }) =>
      student.video ? "var(--text-color-secondary)" : "#0000ff"};
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    line-height: 1.2rem;
  }
  span {
    display: block;
    color: ${({ student }) =>
      student.video ? "var(--text-color-secondary)" : "var(--text-color)"};
  }

  @media (min-width: 1400px) {
    font-size: 0.9rem;
  }
`;
