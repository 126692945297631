import styled from "styled-components";

interface Props {
  paddingTopOnly?: boolean;
}

export const StyledContainer = styled.section<Props>`
  max-width: var(--max-width);
  margin: 0 auto;
  padding: ${({ paddingTopOnly }) =>
    paddingTopOnly ? "1.875rem 0 0" : "1.875rem 0"};
  width: 80%;

  @media (min-width: 360px) {
    width: 70%;
  }

  @media (min-width: 1024px) {
    padding: ${({ paddingTopOnly }) =>
      paddingTopOnly ? "2.5rem 0 0" : "2.5rem 0"};
  }
`;
