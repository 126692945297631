import { useMediaQuery } from "react-responsive";
import Container from "../Container";
import Title from "../Title";

import { Arrow, Background, Content, List } from "./styles";

export default function AverageWage() {
  const isMobileLarge = useMediaQuery({
    query: "(min-width: 540px)",
  });
  return (
    <Background>
      <Container>
        <Content>
          <Title margin={{ mobile: 0, desktop: 1.25 }}>
            Média salarial de programadores por nível:
          </Title>
          <p>
            Saiba quanto ganha um profissional de programação em vários níveis
            de experiência no mercado de trabalho.
          </p>
          <List>
            <li>
              Estágio
              <span>R$ 1.744</span>
            </li>
            <li>
              Júnior
              <span className="red">R$ 3.658</span>
            </li>
            <li>
              Pleno
              <span>R$ 7.387</span>
            </li>
            <li>
              Sênior
              <span className="red">R$ 14.399</span>
            </li>
          </List>

          <p className="source">
            <span>Fonte: </span>
            <a
              href="https://pesquisa.codigofonte.com.br/2022"
              target="_blank"
              rel="noreferrer"
            >
              {isMobileLarge
                ? " https://pesquisa.codigofonte.com.br/2022"
                : "https:// pesquisa.codigofonte.com.br/2022"}
            </a>
          </p>
        </Content>
      </Container>
      <Arrow />
    </Background>
  );
}
