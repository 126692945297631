import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { MdExpandMore } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import Title from "../Title";
import Container from "../Container";
import check from "../../assets/img/check-icon.svg";

import {
  Background,
  HardSkillIcon,
  Content,
  SoftSkillIcon,
  Megaphone,
  MegaphoneYellow,
  MegaphoneBlur,
} from "./styles";

export default function LearningPath() {
  const isMobile = useMediaQuery({
    query: "(min-width: 420px)",
  });

  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  return (
    <Background>
      <Container>
        <Title margin={{ mobile: 1.875, desktop: 1.875 }}>
          Aprenda as principais linguagens e tecnologias de programação e saia
          como o melhor profissional para o mercado de trabalho em apenas 12
          meses:
        </Title>
        <Content>
          <Accordion>
            <AccordionSummary
              expandIcon={<MdExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h3>
                <span>1° Módulo</span>
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <div className="module">
                <h4>
                  <HardSkillIcon />
                  Hard Skills
                </h4>
                <div className="module-section">
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    HTML5 e CSS3
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Web Semântica
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    JavaScript (ES6)
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Lógica de programação
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Controle de versão com git
                  </p>
                </div>
              </div>

              <div className="module">
                <h4>
                  <SoftSkillIcon />
                  Soft Skills
                </h4>
                <div className="module-section">
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Aprender a aprender com os erros
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Gestão de tempo
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Produtividade
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Formação de novos hábitos
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Comunicação clara e assertiva
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Treinamento para entrevista técnica
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Colaboração
                  </p>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<MdExpandMore />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3>
                <span>2°Módulo</span>
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <div className="module">
                <h4>
                  <HardSkillIcon />
                  Hard Skills
                </h4>
                <div className="module-section">
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    JavaScript (ES6)
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Programação Orientada a Objetos
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Programação Funcional
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Metodologias Ágeis
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Qualidade
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    TDD com Jest, BDD com Cucumber
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Noções de UX
                  </p>
                </div>
              </div>

              <div className="module">
                <h4>
                  <SoftSkillIcon />
                  Soft Skills
                </h4>
                <div className="module-section">
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Aprender a aprender com os erros
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Colaboração
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Autonomia
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Comunicação clara e assertiva
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Treinamento para entrevista técnica
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Currículo e LinkedIn (Básico)
                  </p>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<MdExpandMore />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3>
                <span>3° Módulo</span>
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <div className="module">
                <h4>
                  <HardSkillIcon />
                  Hard Skills
                </h4>
                <div className="module-section">
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    React
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Hooks
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    State Management
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Redux
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Componentização, uso de bibliotecas de componentes
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Consumo de APIs
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Cypress
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Metodologias Ágeis
                  </p>
                </div>
              </div>

              <div className="module">
                <h4>
                  <SoftSkillIcon />
                  Soft Skills
                </h4>
                <div className="module-section">
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Autonomia
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Colaboração
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Construção de marca pessoal
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Feedback - como dar e receber
                  </p>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<MdExpandMore />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3>
                <span>4° Módulo</span>
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <div className="module">
                <h4>
                  <HardSkillIcon />
                  Hard Skills
                </h4>
                <div className="module-section">
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    NodeJs, Express
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    TypeScript
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Banco de dados SQL (PostgreSQL)
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Construção de APIs RESTful
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Programação assíncrona
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Metodologias Ágeis
                  </p>
                </div>
              </div>

              <div className="module">
                <h4>
                  <SoftSkillIcon />
                  Soft Skills
                </h4>
                <div className="module-section">
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Flexibilidade
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Mapeamento de competências
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Âncoras de carreira - Assessment
                  </p>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<MdExpandMore />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3>
                <span>5° Módulo</span>
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <div className="module">
                <h4>
                  <HardSkillIcon />
                  Hard Skills
                </h4>
                <div className="module-section">
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Python
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Django
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    ORM
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    MongoDB
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Amazon AWS
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Migrations
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Metodologias Ágeis
                  </p>
                </div>
              </div>

              <div className="module">
                <h4>
                  <SoftSkillIcon />
                  Soft Skills
                </h4>
                <div className="module-section">
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Adaptação a novos ambientes de trabalho
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Persistência
                  </p>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<MdExpandMore />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3>
                <span>6° Módulo</span>
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <div className="module">
                <h4>
                  <HardSkillIcon />
                  Hard Skills
                </h4>
                <div className="module-section">
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Trilha de empregabilidade
                  </p>
                </div>
              </div>

              <div className="module">
                <h4>
                  <SoftSkillIcon />
                  Soft Skills
                </h4>
                <div className="module-section">
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Construção de portfólio
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Planejamento de carreira
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Pitch pessoal
                  </p>
                  <p>
                    <img
                      src={check}
                      alt="Vetor Gráfico"
                      width={24}
                      height={24}
                    />
                    Simulações de entrevista
                  </p>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </Content>
      </Container>

      {isDesktop && <Megaphone />}
      <MegaphoneBlur />
      {isMobile && <MegaphoneYellow />}
    </Background>
  );
}
