import styled from "styled-components";
import megaphoneMbl from "../../assets/img/megaphone-mbl.png";
import megaphone from "../../assets/img/megaphone.png";
import megaphoneBlurMbl from "../../assets/img/megaphone-blur-mbl.png";
import megaphoneBlur from "../../assets/img/megaphone-blur.png";

export const Background = styled.div`
  background: #f0f0f0;
  position: relative;
`;

export const TestimonialsContent = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const Testimonials = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 36rem;
  }
  @media (min-width: 1075px) {
    height: 31rem;
  }
  @media (min-width: 1200px) {
    height: 32rem;
  }
  @media (min-width: 1300px) {
    height: 29rem;
  }
  @media (min-width: 1300px) {
    height: 32rem;
  }
  @media (min-width: 1400px) {
    height: 33rem;
  }
  .column-display {
    display: none;
    @media (min-width: 1024px) {
      display: flex;
    }
  }
`;

export const TestimonialColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  gap: 1rem;
  z-index: 3;

  @media (min-width: 1024px) {
    width: 31%;
    height: 100%;
  }
`;

export const Megaphone = styled.div`
  background: url(${megaphoneMbl}) no-repeat;
  background-size: contain;
  width: 89px;
  height: 241px;
  position: absolute;
  left: 0;
  top: 140px;
  z-index: 2;

  @media (min-width: 1024px) {
    background: url(${megaphone}) no-repeat;
    background-size: contain;
    width: 325px;
    height: 343px;
    left: -140px;
  }

  @media (min-width: 1500px) {
    left: -60px;
  }

  @media (min-width: 1750px) {
    left: 0;
  }
`;
export const MegaphoneBlur = styled.div`
  background: url(${megaphoneBlurMbl}) no-repeat;
  background-size: contain;
  width: 81px;
  height: 134px;
  position: absolute;
  top: -40px;
  right: 0;
  z-index: 2;

  @media (min-width: 1024px) {
    background: url(${megaphoneBlur}) no-repeat;
    background-size: contain;
    width: 350px;
    height: 384px;
    right: -270px;
    top: auto;
    bottom: -40px;
  }
`;
