import styled, { css } from "styled-components";

interface Props {
  secondary: boolean;
  color: string;
  noteColor?: string;
  input?: { background?: string; color?: string };
}

interface InputContainerProps {
  secondary: boolean;
}

export const Form = styled.form<Props>`
  display: flex;
  flex-direction: column;
  align-items: ${({ secondary }) => (secondary ? "flex-start" : "center")};

  div.boxes {
    @media (min-width: 1024px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      ${({ secondary }) =>
        secondary &&
        css`
          flex-direction: column;
          width: 100%;
        `}
    }
  }

  p.terms {
    font-size: 0.625rem;
    font-family: "Open Sans", sans-serif;
    margin-top: 1.375rem;
    color: ${({ noteColor }) => noteColor ?? "#fff"};
    max-width: none;

    @media (min-width: 1024px) {
      margin-top: 2rem;
      font-size: 0.75rem;
    }

    a {
      text-decoration: underline;
      color: ${({ noteColor }) => noteColor ?? "#fff"};
    }
  }

  input,
  select {
    width: 100%;
    height: 4rem;
    padding: 1rem;
    font-size: 1.2rem;
    color: ${({ input }) => input?.color ?? "var(--text-color-main)"};
    background-color: ${({ input }) => input?.background ?? "#fff"};
    border: 1px solid transparent;
    border-radius: var(--btn-border-radius);
    outline: none;
    margin-bottom: 1rem;
    box-shadow: 3px 3px 4px 0 #00000026;

    &:focus {
      box-shadow: 0 0 1px 1px #f8c808;
    }
    &::-webkit-input-placeholder {
      font-size: 0.8rem;

      ${({ input }) =>
        input?.color &&
        css`
          color: #909090;
        `}
    }
    @media (min-width: 400px) {
      font-size: 1.25rem;

      &::-webkit-input-placeholder {
        font-size: 1rem;
      }
    }

    @media (min-width: 1024px) {
      width: 100%;
      font-size: 1.125rem;
      margin: ${({ secondary }) => (secondary ? "0 0 1rem 0" : "0 0 0 0")};

      ${({ secondary }) =>
        !secondary &&
        css`
          &::-webkit-input-placeholder {
            font-size: 0.8rem;
          }
        `}
    }

    @media (min-width: 1200px) {
      &::-webkit-input-placeholder {
        font-size: 1rem;
      }
    }
  }

  select {
    font-size: 0.8rem;
    color: #909090;

    &:active,
    &:valid {
      color: var(--text-color-main);
    }

    option {
      color: var(--text-color-main);
    }

    @media (min-width: 400px) {
      font-size: 1rem;
    }

    @media (min-width: 1024px) {
      font-size: 1.0875rem;
    }
  }

  button {
    font-family: "JetBrains Mono", monospace;
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    height: 4rem;
    padding: 0.5rem;
    background-color: ${({ color }) => color};
    border-radius: 5.625rem;
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      box-shadow: 0 0 8px 4px ${({ color }) => color};
    }

    @media (min-width: 400px) {
      font-size: 1.125rem;
    }

    @media (min-width: 1024px) {
      width: 100%;
      font-size: 1.25rem;
    }
  }
`;

export const InputContainer = styled.div<InputContainerProps>`
  @media (min-width: 1024px) {
    ${({ secondary }) =>
      secondary
        ? css`
            display: flex;
            flex-direction: column;
            width: 100%;
          `
        : css`
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 0.5rem;
          `}
  }
`;
