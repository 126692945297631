import styled from "styled-components";

interface Props {
  color?: string;
  margin?: { mobile: number; desktop: number };
}

export const StyledTitle = styled.h2<Props>`
  font-size: 1.375rem;
  font-weight: 800;
  color: ${({ color }) => (color ? color : "var(--text-color-main)")};
  margin-bottom: ${({ margin }) => (margin ? `${margin.mobile}rem` : "1rem")};
  text-transform: uppercase;

  @media (min-width: 1024px) {
    margin-bottom: ${({ margin }) =>
      margin ? `${margin.mobile}rem` : "2.5rem"};
    font-size: 2.188rem;
  }
`;
