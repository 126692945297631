import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useMediaQuery } from "react-responsive";
import ActiveCampaignForm from "../ActiveCampaignForm";
import duckMbl from "../../assets/img/duck-mbl.png";
import duck from "../../assets/img/duck.png";

import { Container, Content, DuckContainer } from "./styles";

interface Props {
  setOpenModal: (bool: boolean) => void;
  open: boolean;
}

export default function PopUp({ setOpenModal, open }: Props) {
  const handleClose = () => setOpenModal(false);
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    bgcolor: "#0000FF",
    borderRadius: "25px",
    boxShadow: 24,
    maxWidth: isDesktop ? "50rem" : "22rem",
    overflow: "hidden",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
    >
      <Box sx={style}>
        <Container>
          <button onClick={handleClose} className="close">
            <svg width="10" height="10" fill="none" viewBox="0 0 26 25">
              <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M2.5 1.5l21.363 21.063M23.863 1.5L2.5 22.563"
              ></path>
            </svg>
          </button>

          {isDesktop && (
            <img
              src={duck}
              alt="Desenho do patinho da Kenzie com lágrimas escorrendo do rosto"
              width={333}
              height={407}
            />
          )}

          <Content>
            {isDesktop ? (
              <strong>estou te vendo sair... 👀 </strong>
            ) : (
              <DuckContainer>
                <strong>estou te vendo sair... 👀 </strong>
                <img
                  src={duckMbl}
                  alt="Desenho do patinho da Kenzie com lágrimas escorrendo do rosto"
                  width={71}
                  height={89}
                />
              </DuckContainer>
            )}
            <p>
              Faça sua inscrição agora, inicie sua formação e garanta um
              desconto especial!
            </p>
            <ActiveCampaignForm
              color="#ee312a"
              noteColor="#fff"
              secondarySchema
            />
          </Content>
        </Container>
      </Box>
    </Modal>
  );
}
