import styled from "styled-components";

interface Props {
  fixed: boolean;
}

export const StyledHeader = styled.header<Props>`
  margin: 0 auto;
  height: ${({ fixed }) => (fixed ? "12.125rem" : "4.125rem")};
  background-color: var(--red);
  position: fixed;
  left: 0;
  right: 0;
  box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 100;
  transition: height 0.1s ease-in-out;

  @media (min-width: 400px) {
    height: ${({ fixed }) => (fixed ? "8.5rem" : "4.125rem")};
  }

  @media (min-width: 1024px) {
    height: ${({ fixed }) => (fixed ? "7.5rem" : "5.125rem")};
  }
`;

export const Container = styled.div<Props>`
  height: 100%;
  max-width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .cta-container {
    p {
      display: ${({ fixed }) => (fixed ? "block" : "none")};
      font-size: 0.625rem;
      color: var(--text-color-secondary);
    }
  }
  .cta {
    background: var(--brand-color);
    color: var(--text-color-secondary);
    width: 14.438rem;
    height: 2.375rem;
    padding: 1rem 0;
    border-radius: 10px;
    font-family: "JetBrains Mono", sans-serif;
    font-weight: 800;
    font-size: 0.75rem;
    text-transform: uppercase;
    display: ${({ fixed }) => (fixed ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 0.3s ease-in-out;

    &:hover {
      filter: brightness(0.8);
    }
  }

  @media (min-width: 635px) and (max-width: 768px) {
    gap: 0.5rem;
  }

  @media (min-width: 1024px) {
    max-width: var(--max-width);
    justify-content: space-between;
    flex-wrap: nowrap;

    .cta-container {
      p {
        text-align: center;
        margin-top: -0.5rem;
      }
    }

    .cta {
      display: flex;
      width: 17.5rem;
      height: auto;
      font-size: 1rem;
      border-radius: ${({ fixed }) => (fixed ? "50px" : "5px")};
    }
  }
`;

export const Content = styled.div<Props>`
  display: ${({ fixed }) => (fixed ? "flex" : "none")};
  align-items: center;
  margin: 0 auto;
  gap: 2rem;
`;

export const Logo = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  @media (min-width: 1024px) {
    margin: 0;
    a img {
      width: 180px;
      height: 45px;
    }
  }
  @media (min-width: 1024px) and (max-width: 1170px) {
    margin-left: 1rem;
  }
`;

export const Countdown = styled.div<Props>`
  display: flex;

  .date {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    p {
      background: rgba(255, 255, 255, 0.3);
      border: 2px solid var(--text-color-secondary);
      color: var(--text-color-secondary);
      backdrop-filter: blur(5px);
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 1.375rem;
      padding: 1rem;
      width: 2.25rem;
      height: 2.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span {
      font-size: 0.313rem;
      color: var(--text-color-secondary);
      text-transform: uppercase;
    }
  }

  .separator {
    font-size: 1.563rem;
    margin: 0.1rem 0.3rem 0 0.3rem;
    color: var(--text-color-secondary);
  }

  @media (max-width: 400px) {
    margin-left: 1rem;
  }

  @media (min-width: 1024px) {
    .date {
      p {
        font-size: 1.563rem;
        width: 3.063rem;
        height: 3.063rem;
      }
      span {
        font-size: 0.438rem;
      }
    }

    .separator {
      font-size: 1.563rem;
      margin: 0.5rem 0.5rem 0;
    }
  }
`;
