import { useMediaQuery } from "react-responsive";
import { ReactComponent as Suitcase } from "../../assets/img/suitcase.svg";
import { ReactComponent as People } from "../../assets/img/people.svg";
import { ReactComponent as Timer } from "../../assets/img/timer.svg";
import { ReactComponent as Pc } from "../../assets/img/pc.svg";
import Cta from "../Cta";

import {
  Arrow,
  BgPeople,
  Container,
  Content,
  List,
  StyledButton,
  Wrapper,
} from "./styles";

interface Props {
  onClick?: () => void;
}

export default function Hero({ onClick }: Props) {
  const isXLarge = useMediaQuery({
    query: "(min-width: 1700px)",
  });

  return (
    <Wrapper>
      <Container>
        <Content>
          <h1>
            ganhe até <span>R$ 14 MIL/MêS </span> como uma pessoa programadora
          </h1>
          <p>
            Aprenda programação do zero em apenas 12 meses, torne-se um
            profissional completo e conecte-se às vagas das empresas mais
            desejadas do mercado.
          </p>
          <p className="highlight">
            Pague uma entrada de 5% do valor do curso e o restante em até 36x
            com a primeira parcela após 12 meses
          </p>

          <List>
            <li>
              <Suitcase />
              <h3>Colocação no Mercado</h3>
              <p>
                Mais de 200 empresas parceiras e 98% de taxa de empregabilidade
                em até 3 meses após formado.
              </p>
            </li>
            <li>
              <People />
              <h3>Prática e Mentoria</h3>
              <p>
                Alunos monitores e instrutores disponíveis para te ajudar nas
                tarefas durante toda a formação.
              </p>
            </li>
            <li>
              <Timer />
              <h3>Suporte Total</h3>
              <p>
                Suporte 100% humanizado que responde suas dúvidas em até 5
                minutos.
              </p>
            </li>
            <li>
              <Pc />
              <h3>Metodologia do Vale do Silício</h3>
              <p>
                Metodologia de ensino exclusiva em aulas que acontecem ao vivo.
              </p>
            </li>
          </List>

          {onClick ? (
            <StyledButton onClick={onClick}>
              Quero fazer a minha inscrição
            </StyledButton>
          ) : (
            <Cta>Quero fazer a minha inscrição</Cta>
          )}

          <p className="privacy">
            Declaro que li, compreendi e estou de acordo com os{" "}
            <a
              href="https://formulario-kenzie.vercel.app/termos-condicoes"
              target="_blank"
              rel="noreferrer"
            >
              Termos de Uso e com a Política de Privacidade.
            </a>
          </p>
        </Content>
        {isXLarge && <Arrow />}
      </Container>
      <BgPeople />
    </Wrapper>
  );
}
