import axios, { AxiosInstance } from "axios";

const admissionsApi: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

admissionsApi.defaults.headers.post["Content-Type"] =
  "application/json; charset=utf-8";

export { admissionsApi };
