import styled from "styled-components";
import { FaLaptopCode, FaGlobe } from "react-icons/fa";
import megaphone from "../../assets/img/megaphone.png";
import megaphoneBlur from "../../assets/img/megaphone-blur-right.png";
import megaphoneYellow from "../../assets/img/megaphone-yellow.png";

export const Background = styled.div`
  background: #f0f0f0;
  position: relative;
`;

export const Content = styled.div`
  div {
    background-color: transparent;
    color: var(--text-color);
    font-size: 1.5rem;
    text-align: left;
    z-index: 3;
  }

  div.MuiAccordionSummary-root {
    background-color: var(--text-color-secondary);
    border-radius: 0.5rem;
    padding: 0 1.5rem;
    z-index: 3;

    &.Mui-expanded {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  div.MuiAccordionSummary-content {
    font-size: 1.2rem;
    padding: 0.1rem 0.5rem;
    color: var(--text-color);
    z-index: 3;

    span {
      display: flex;
      align-items: center;
      font-size: 1.625rem;

      b {
        margin-right: 5px;
      }
    }
  }

  .MuiSvgIcon-root {
    path {
      fill: var(--text-color);
    }
  }

  .MuiAccordion-root:before {
    background: none;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  div.MuiAccordion-rounded {
    border-radius: 0.5rem;
    margin: 2rem 0;

    h3 {
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
    }

    div.MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      background-color: var(--text-color-secondary);

      @media (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
        padding: 2rem;
      }

      div.module {
        h4 {
          font-size: 1.2rem;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          font-weight: 800;
          margin-bottom: 1.5rem;

          @media (min-width: 1024px) {
            font-size: 2rem;
          }
        }

        div.module-section {
          display: flex;
          flex-direction: column;
          font-size: 1.2rem;

          p {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;

            @media (min-width: 1024px) {
              font-size: 1.5rem;
            }

            img {
              min-width: 1.5rem;
              margin-right: 0.5rem;

              @media (min-width: 1024px) {
                min-width: 1.875rem;
                height: 30px;
                width: 2.5rem;
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }
  }
`;

export const HardSkillIcon = styled(FaLaptopCode)`
  font-size: 2.5rem;
  color: #007aff;
  margin-right: 0.5rem;
`;

export const SoftSkillIcon = styled(FaGlobe)`
  font-size: 2.5rem;
  color: #007aff;
  margin-right: 0.5rem;
`;

export const Megaphone = styled.div`
  background: url(${megaphone}) no-repeat;
  background-size: contain;
  width: 261px;
  height: 291px;
  transform: rotate(-40deg);
  position: absolute;
  right: -80px;
  bottom: 100px;

  @media (min-width: 1800px) {
    right: 20px;
  }
`;

export const MegaphoneBlur = styled.div`
  background: url(${megaphoneBlur}) no-repeat;
  background-size: contain;
  width: 198px;
  height: 217px;
  position: absolute;
  bottom: 50px;
  right: -40px;

  @media (min-width: 1024px) {
    right: auto;
    left: 60px;
    bottom: 20px;
  }
  @media (min-width: 1920px) {
    left: 240px;
  }
`;

export const MegaphoneYellow = styled.div`
  background: url(${megaphoneYellow}) no-repeat;
  background-size: contain;
  width: 107px;
  height: 118px;
  position: absolute;
  top: 240px;
  left: -20px;
  z-index: 1;

  @media (min-width: 1024px) {
    width: 282px;
    height: 328px;
    top: 190px;
  }

  @media (min-width: 1400px) {
    top: 100px;
  }

  @media (min-width: 1920px) {
    left: 20px;
    top: 50px;
  }
`;
