import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  z-index: 8;
  bottom: 0;
  left: 80%;
  right: 0;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  transform-origin: bottom;
  transition: transform 0.3s linear;

  @media (min-width: 360px) {
    left: 84%;
  }

  @media (min-width: 496px) {
    left: 88%;
  }

  @media (min-width: 768px) {
    padding: 1rem;
    left: 87%;
  }

  @media (min-width: 1100px) {
    width: fit-content;
    background-color: transparent;
    margin-left: auto;
  }
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  outline: none;
  width: 3rem;
  height: 3rem;

  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;

  svg {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }

  @media (min-width: 768px) {
    width: 5rem;
    height: 5rem;

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
`;
