import CristianeImg from "../assets/img/testimonial-1.png";
import BrunoImg from "../assets/img/testimonial-2.png";
import IngridImg from "../assets/img/testimonial-3.png";
import FrancielleImg from "../assets/img/testimonial-4.png";
import MarianaImg from "../assets/img/testimonial-5.png";
import AugustoImg from "../assets/img/testimonial-6.png";

export const testimonialsC1 = [
  {
    name: "Cristiane Balsini",
    genre: "feminino",
    role: "Aluna da Kenzie",
    testimonial: [
      "“A Kenzie em mais ou menos 5 meses conseguiu me capacitar para entrar no mercado de trabalho.”",
    ],

    image: CristianeImg,
  },
  {
    name: "Julia Sperandio",
    genre: "feminino",
    role: "Aluna da Kenzie",
    video: "lBpHLDgpWJA",
    videoHeight: 35,
    videoId: 1,
  },
  {
    name: "Bruno Alexandre",
    genre: "masculino",
    role: "Aluno da Kenzie",
    testimonial: [
      "“Ativei minha rede de contatos na Kenzie, fiz 1 entrevista, em 3 dias recebi uma proposta e já comecei a trabalhar na área.”",
    ],
    image: BrunoImg,
  },
];

export const testimonialsC2 = [
  {
    name: "Ingrid Benites",
    genre: "feminino",
    role: "Aluna da Kenzie",
    testimonial: [
      "“Aprendi programação do zero com a Kenzie e é incrível como em apenas 6 meses eu já consegui um emprego na área como desenvolvedora web e trabalhando com algo que eu sempre sonhei. E melhor ainda, contratada por uma empresa dos Estados Unidos. Aqui as opotunidades são reais! Antes de estudar na Kenzie eu era professora de inglês, mas eu sempre gostei de tecnologia, principalmente da área de programação.    ",
      " Eu já fiz vários cursos antes mas nenhum deles foi parecido com a Kenzie. Eles tem uma proposta bem diferente, você sente que eles estão preocupados com o seu desempenho.”",
    ],
    image: IngridImg,
  },
];

export const testimonialsC3 = [
  {
    name: "Fracielle da Silva",
    genre: "feminino",
    role: "Aluna da Kenzie",
    testimonial: [
      "“Com apenas 6 meses de curso eu consegui minha primeira oportunidade como programadora. Pra mim, qualquer pessoa que tem o sonho de ser tornar desenvolvedor, deveria assistir uma aula da Kenzie. É incrível.”",
    ],
    image: FrancielleImg,
  },
  {
    name: "Franciele Cristine",
    genre: "feminino",
    role: "Aluna da Kenzie",
    testimonial: [
      "“Uma das coisas mais importantes que eu aprendi na Kenzie foi a não desistir mesmo diante das dificuldades, por isso eu amo estudar aqui, porque o ensino vai muito além de habilidades técnicas, entregas de trabalhos e notas!”.",
    ],
    image: MarianaImg,
  },
];

export const testimonialsC4 = [
  {
    name: "Paola Eurich",
    genre: "feminino",
    role: "Aluna da Kenzie",
    video: "jaR-RQv1UHc",
    videoHeight: 20,
    videoId: 2,
  },

  {
    name: "Augusto Pietroski",
    genre: "masculino",
    role: "Aluno da Kenzie",
    testimonial: [
      "“Conquistar minha primeira vaga no mercado internacional, com apenas 9 meses de curso em uma empresa dos Estados Unidos foi algo diferenciado. A Kenzie me deu os fundamentos para que eu conquistasse essa vaga. Quando ela diz que prepara o profissional para o mercado, ela não está brincando!”",
    ],
    image: AugustoImg,
  },
];
