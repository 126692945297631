import styled from "styled-components";
import smiles from "../../assets/img/smiles.png";
import bgGridMbl from "../../assets/img/bg-grid-mbl.png";
import bgGrid from "../../assets/img/bg-grid.png";
import frame from "../../assets/img/frame.png";
import chess from "../../assets/img/chess.png";
import megaphone from "../../assets/img/megaphone.png";
import megaphoneBlur from "../../assets/img/megaphone-blur.png";
import coin from "../../assets/img/coin.png";

export const Background = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Smiles = styled.div`
  background: url(${smiles}) no-repeat;
  background-size: contain;
  width: 91px;
  height: 106px;
  position: absolute;
  top: 450px;
  right: -35px;

  @media (min-width: 440px) {
    top: 380px;
  }

  @media (min-width: 540px) {
    top: 280px;
  }

  @media (min-width: 768px) {
    top: 250px;
  }

  @media (min-width: 1024px) {
    width: 151px;
    height: 176px;
    top: 910px;
  }

  @media (min-width: 1300px) {
    top: 870px;
  }
`;

export const Grid = styled.div`
  background: url(${bgGridMbl}) no-repeat;
  background-size: contain;
  width: 71px;
  height: 392px;
  position: absolute;
  top: 38%;
  left: 0;
  z-index: -1;

  @media (min-width: 500px) {
    top: 30%;
  }
  @media (min-width: 630px) {
    top: 22%;
  }
  @media (min-width: 1024px) {
    top: 35%;
  }

  @media (min-width: 1500px) {
    background: url(${bgGrid}) no-repeat;
    background-size: contain;
    width: 574px;
    height: 610px;
    top: 382px;
    left: -40px;
    z-index: 1;
  }
  @media (min-width: 1920px) {
    width: 30%;
  }

  @media (min-width: 2500px) {
    width: 35%;
  }
`;

export const Frame = styled.div`
  background: url(${frame}) no-repeat;
  background-size: contain;
  width: 35px;
  height: 399px;
  transform: rotate(180deg);
  position: absolute;
  top: 1450px;
  left: 0;

  @media (min-width: 540px) {
    top: 1250px;
  }

  @media (min-width: 1024px) {
    height: 768px;
    top: 1200px;
  }

  @media (min-width: 1200px) {
    top: 1150px;
  }
`;

export const Chess = styled.div`
  background: url(${chess}) no-repeat;
  background-size: contain;
  width: 359px;
  height: 314px;
  position: absolute;
  top: 236px;
  right: -250px;

  @media (min-width: 1700px) {
    right: -200px;
  }
`;

export const Megaphone = styled.div`
  background: url(${megaphone}) no-repeat;
  background-size: contain;
  width: 93px;
  height: 104px;
  transform: rotate(-20deg);
  position: absolute;
  top: 920px;
  left: 50px;
  z-index: 2;
`;

export const MegaphoneBlur = styled.div`
  background: url(${megaphoneBlur}) no-repeat;
  background-size: contain;
  width: 198px;
  height: 217px;
  position: absolute;
  top: 620px;
  right: 30px;

  @media (min-width: 1350px) {
    top: 510px;
  }

  @media (min-width: 1550px) {
    top: 630px;
  }

  @media (min-width: 1920px) {
    right: 150px;
  }
`;

export const Coin = styled.div`
  background: url(${coin}) no-repeat;
  background-size: contain;
  width: 140px;
  height: 125px;
  position: absolute;
  top: 1550px;
  left: -80px;

  @media (min-width: 488px) {
    top: 1340px;
  }

  @media (min-width: 605px) {
    top: 1250px;
  }

  @media (min-width: 720px) {
    top: 1200px;
  }

  @media (min-width: 744px) {
    top: 1150px;
  }

  @media (min-width: 865px) {
    top: 1080px;
  }

  @media (min-width: 996px) {
    top: 1000px;
  }

  @media (min-width: 1024px) {
    width: 299px;
    height: 266px;
    top: 1550px;
    left: -150px;
  }

  @media (min-width: 1156px) {
    top: 1400px;
  }

  @media (min-width: 1350px) {
    top: 1340px;
  }

  @media (min-width: 1550px) {
    top: 1450px;
  }
`;

export const SecondCoin = styled.div`
  background: url(${coin}) no-repeat;
  background-size: contain;
  width: 142px;
  height: 194px;
  position: absolute;
  bottom: 550px;
  right: -30px;

  @media (min-width: 1200px) {
    bottom: 340px;
  }

  @media (min-width: 1350px) {
    bottom: 320px;
  }
`;
