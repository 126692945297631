import BaseAPI from "./base-api";

export type CreateParams = {
  email: string;
  availability: string;
  required_equipment: string;
  first_name: string;
  last_name: string;
  phone: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  hsa_ad?: string;
  hsa_grp?: string;
  hsa_cam?: string;
  hsa_src?: string;
  origin_release?: string;
  campaign_release?: string;
  indicator_email?: string;
  indicator_name?: string;
  indicator?: string;
  referral?: string;
  recommendation_id?: string;
  payment_method: string;
  transaction_id?: string;
};

export type CreateResponse = {
  subscription?: {
    email: string;
    first_name: string;
    last_name: string;
  };
  error?: any;
};

export interface ISubscriptionService {
  create: (params: CreateParams) => Promise<CreateResponse>;
}

export class SubscriptionService
  extends BaseAPI
  implements ISubscriptionService
{
  async create(params: CreateParams): Promise<CreateResponse> {
    try {
      const response = await this.http.post(`/subscriptions`, params);
      const subscription = response.data.data.subscription;
      return { subscription };
    } catch (error: any) {
      return { error: error.response?.data };
    }
  }
}
