import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/img/logo.png";

import { Container, Content, Logo, StyledHeader } from "./styles";

interface HeaderProps {
  onClick?: () => void;
  v2?: boolean;
}

export default function Header({ onClick, v2 }: HeaderProps) {
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const { search, pathname } = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (window.scrollY > 0) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    });
  }, []);

  return (
    <StyledHeader fixed={isFixed}>
      <Container fixed={isFixed}>
        <Logo fixed={isFixed}>
          <a rel="noreferrer" href={`/`}>
            <img src={logo} alt="Kenzie Academy" width="120" height="30" />
          </a>
        </Logo>
        <Content fixed={isFixed}></Content>
        <div className="cta-container">
          <p>
            Formação com <strong>20% OFF</strong> por tempo limitado!
          </p>

          {v2 ? (
            <button className="cta" onClick={onClick}>
              Fazer inscrição agora
            </button>
          ) : (
            <a
              className="cta"
              href={
                pathname === "/influ"
                  ? `https://inscricao.kenzie.com.br/influ${search}`
                  : `https://inscricao.kenzie.com.br/${search}`
              }
              rel="noreferrer"
            >
              Fazer inscrição agora
            </a>
          )}
        </div>
      </Container>
    </StyledHeader>
  );
}
