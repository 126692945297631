import { StyledTitle } from "./styles";

interface Props {
  children: string;
  color?: string;
  margin?: { mobile: number; desktop: number };
}

export default function Title({ children, color, margin }: Props) {
  return (
    <StyledTitle color={color} margin={margin}>
      {children}
    </StyledTitle>
  );
}
