import styled from "styled-components";
import globe from "../../assets/img/globe.png";
import handshake from "../../assets/img/handshake.png";

export const Background = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1550px) {
    flex-direction: row;
    gap: 1rem;

    h2 {
      min-width: 24rem;
      width: 24rem;
      font-size: 2.813rem;
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  img {
    object-fit: contain;
  }

  @media (min-width: 1024px) {
    gap: 1.25rem;
    img {
      width: 118px;
      height: 59px;
    }
  }

  @media (min-width: 1550px) {
    max-width: 42.125rem;
    row-gap: 3.75rem;
    align-self: flex-end;

    img {
      &:nth-child(31),
      &:nth-child(32) {
        display: none;
      }
    }
  }
`;

export const Globe = styled.div`
  background: url(${globe}) no-repeat;
  background-size: contain;
  width: 40px;
  height: 92px;
  position: absolute;
  top: 0;
  right: 5px;

  @media (min-width: 420px) {
    width: 60px;
    height: 112px;
  }

  @media (min-width: 768px) {
    right: 40px;
  }

  @media (min-width: 1024px) {
    width: 137px;
    height: 256px;
    right: 10px;
  }
  @media (min-width: 1600px) {
    right: 100px;
  }
`;

export const Handshake = styled.div`
  background: url(${handshake}) no-repeat;
  background-size: contain;
  width: 872px;
  height: 382px;
  position: absolute;
  bottom: -10px;
  left: -80px;

  @media (min-width: 1750px) {
    left: 0;
  }
`;
