import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputMask from "react-input-mask";
import { SubscriptionService } from "../../services/subscription-service";
import { admissionsApi } from "../../services/axios";

import { Form, InputContainer } from "./styles";

type ActiveCampaignFormProps = {
  secondarySchema?: boolean;
  color: string;
  noteColor?: string;
  input?: { background?: string; color?: string };
};

type FormInputs = {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  payment_method: string;
};

export default function ActiveCampaignForm({
  color,
  noteColor,
  input,
  secondarySchema = false,
}: ActiveCampaignFormProps) {
  const transactionId = `${Date.now()}${Math.random()
    .toString(36)
    .substring(2)}`.slice(0, 20);
  localStorage.setItem("lomadee_id", transactionId);

  const [searchParams] = useSearchParams();

  function capitalizeFirstName(name: string) {
    return name
      .split("")
      .map((letter, index) =>
        index === 0 ? letter.toUpperCase() : letter.toLowerCase()
      )
      .toString()
      .replaceAll(",", "");
  }

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    phone: yup
      .string()
      .matches(/\(\d{2}\)\s\d{4,5}-\d{4}/)
      .required(),
    payment_method: yup.string().required(),
  });

  const { register, handleSubmit } = useForm<FormInputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  async function onSubmit(data: any) {
    data["first_name"] = capitalizeFirstName(data["first_name"]);
    data["utm_source"] = searchParams.get("utm_source") ?? "no-tracking";
    data["utm_medium"] = searchParams.get("utm_medium") ?? "no-tracking";
    data["utm_campaign"] = searchParams.get("utm_campaign") ?? "no-tracking";
    data["utm_content"] = searchParams.get("utm_content") ?? "no-tracking";
    data["utm_term"] = searchParams.get("utm_term") ?? "no-tracking";
    data["hsa_ad"] = searchParams.get("hsa_ad") ?? "no-tracking";
    data["hsa_grp"] = searchParams.get("hsa_grp") ?? "no-tracking";
    data["hsa_cam"] = searchParams.get("hsa_cam") ?? "no-tracking";
    data["hsa_src"] = searchParams.get("hsa_src") ?? "no-tracking";
    data["origin_release"] =
      searchParams.get("origem_lancamento") ?? "no-tracking";
    data["campaign_release"] =
      searchParams.get("campanha_lancamento") ?? "no-tracking";
    data["indicator_email"] =
      searchParams.get("EMAIL_DO_INDICADOR") ?? "no-tracking";
    data["indicator_name"] =
      searchParams.get("NOME_DO_INDICADOR") ?? "no-tracking";
    data["indicator"] = searchParams.get("utm_indicator") ?? "no-tracking";
    data["referral"] = searchParams.get("utm_referral") ?? "no-tracking";
    data["recommendation_id"] =
      searchParams.get("utm_recommendation_id") ?? "no-tracking";
    data["transaction_id"] = transactionId;

    data["required_equipment"] = "Pending answer";
    data["availability"] = "Pending answer";

    const subscriptionService = new SubscriptionService(admissionsApi);
    await subscriptionService.create(data);

    window.location.href = "/completed_subscription";
  }

  useEffect(() => {
    sessionStorage.setItem(
      "influencerId",
      searchParams.get("squid-share") ?? "no-tracking"
    );
    sessionStorage.setItem("transactionId", transactionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      secondary={secondarySchema}
      color={color}
      noteColor={noteColor}
      input={input}
    >
      <div className="boxes">
        <InputContainer secondary={secondarySchema}>
          <input
            type="text"
            placeholder="Digite aqui seu nome"
            required
            {...register("first_name")}
          />

          <input
            type="text"
            placeholder="Digite aqui seu sobrenome"
            required
            {...register("last_name")}
          />

          <input
            type="email"
            placeholder="Digite aqui o seu e-mail"
            required
            {...register("email")}
          />

          <InputMask
            mask="(99) 99999-9999"
            pattern="\([0-9]{2}\)\s9[0-9]{4}-[0-9]{4}"
            type="tel"
            placeholder="Digite aqui o seu telefone"
            required
            {...register("phone")}
          />

          <select {...register("payment_method")} required>
            <option value="">Opções de pagamento</option>
            <option value="Financiado (Carência)">
              Financiado (R$24.000 | 5% entrada + até 36x após 12 meses)
            </option>
            <option value="À Vista">
              À Vista (R$17.600 - 20% de desconto)
            </option>
            <option value="Parcelado">
              Parcelado (R$21.600 | 12x de R$1.800 com 10% de desconto)
            </option>
          </select>
        </InputContainer>

        <button type="submit">Quero fazer a minha inscrição</button>
      </div>

      <p className="terms">
        Na qualidade de Usuário declaro que li, compreendi e estou de acordo com
        os{" "}
        <a
          href="https://formulario-kenzie.vercel.app/termos-condicoes"
          target="_blank"
          rel="noreferrer"
        >
          Termos de Uso e com a Política de Privacidade.
        </a>
      </p>
    </Form>
  );
}
