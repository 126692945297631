import styled from "styled-components";
import arrow from "../../assets/img/average-arrow.png";

export const Background = styled.div`
  background: #f0f0f0;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .source {
    font-size: 0.5rem;
    max-width: 9rem;
    align-self: flex-end;
    span {
      font-weight: 700;
    }
  }

  @media (min-width: 540px) {
    .source {
      align-self: flex-start;
      max-width: none;
    }
  }

  @media (min-width: 1024px) {
    p {
      font-size: 1.375rem;
      max-width: 61.625rem;
    }

    .source {
      font-size: 0.625rem;
    }
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.563rem;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 0.875rem;

    span {
      display: block;
      background: var(--brand-color);
      width: 10rem;
      border-radius: 5px;
      padding: 0.5rem 0;
      color: var(--text-color-secondary);
      text-align: center;

      &.red {
        background: var(--red);
      }
    }
  }

  @media (min-width: 540px) {
    flex-direction: row;
    align-items: end;

    li {
      flex-direction: column;
      width: 100%;

      span {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
        border-radius: 10px;
      }

      &:nth-child(2) {
        span {
          height: 1.5rem;
        }
      }

      &:nth-child(3) {
        span {
          height: 1.8rem;
        }
      }

      &:nth-child(4) {
        span {
          height: 2.1rem;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    li {
      span {
        height: 2.5rem;
      }
      &:nth-child(2) {
        span {
          height: 3.5rem;
        }
      }

      &:nth-child(3) {
        span {
          height: 4.5rem;
        }
      }

      &:nth-child(4) {
        span {
          height: 5.5rem;
        }
      }
    }
  }
`;

export const Arrow = styled.div`
  background: url(${arrow}) no-repeat;
  background-size: contain;
  width: 128px;
  height: 17px;
  position: absolute;
  left: -113px;
  top: 40px;

  @media (min-width: 400px) {
    left: -80px;
  }

  @media (min-width: 768px) {
    left: -30px;
  }

  @media (min-width: 1024px) {
    width: 248px;
    height: 33px;
    left: -110px;
    top: 50px;
  }

  @media (min-width: 1600px) {
    left: -20px;
  }

  @media (min-width: 1700px) {
    left: 0;
  }
`;
