import styled from "styled-components";
import personMbl from "../../assets/img/person-indicated-mbl.png";
import person from "../../assets/img/person-indicated.png";

export const Background = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Person = styled.div`
  background: url(${personMbl}) no-repeat;
  background-size: contain;
  width: 153px;
  height: 234px;
  position: absolute;
  bottom: 0;
  left: -5px;
  z-index: -1;

  @media (min-width: 1024px) {
    left: 0;
    z-index: 2;
  }

  @media (min-width: 1200px) {
    background: url(${person}) no-repeat;
    background-size: contain;
    width: 365px;
    height: 559px;
    bottom: -30px;
    left: -60px;
  }

  @media (min-width: 1500px) {
    left: 10%;
  }
  @media (min-width: 1700px) {
    left: -450px;
    bottom: -80px;
  }
`;

export const Wrapper = styled.div`
  @media (min-width: 1024px) {
    max-width: 45.625rem;
    margin-left: auto;
    position: relative;
  }
`;

export const Content = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.125rem;
  z-index: 2;

  li {
    display: flex;
    gap: 0.875rem;

    svg {
      height: 35px;
      width: 35px;
      min-height: 35px;
      min-width: 35px;
      margin-top: 0.3rem;
    }
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.875rem;
    li {
      p {
        font-size: 1.25rem;
        max-width: 18.063rem;
      }
      svg {
        height: 50px;
        width: 50px;
        min-height: 50px;
        min-width: 50px;
      }
    }
  }
`;
