import { ReactComponent as item1 } from "../../assets/img/list-item-1.svg";
import { ReactComponent as item2 } from "../../assets/img/list-item-2.svg";
import { ReactComponent as item3 } from "../../assets/img/list-item-3.svg";
import { ReactComponent as item4 } from "../../assets/img/list-item-4.svg";
import { ReactComponent as item5 } from "../../assets/img/list-item-5.svg";
import { ReactComponent as item6 } from "../../assets/img/list-item-6.svg";

export const items = [
  {
    icon: item1,
    text: "Ter a oportunidade de ganhar salários de R$3.000 a R$14.000 por mês",
  },
  {
    icon: item2,
    text: "Aprender a programar do zero e ter uma carreira sólida mesmo sem faculdade",
  },
  {
    icon: item3,
    text: "Trabalhar remotamente de qualquer lugar do país ou do mundo",
  },
  {
    icon: item4,
    text: "Poderá ganhar uma bolsa de até R$500/mês sendo aluno monitor",
  },
  {
    icon: item5,
    text: "Entrar para uma área que só cresce e é 100% à prova de crises",
  },
  {
    icon: item6,
    text: "Carreira com crescimento contínuo e aprendizado constante",
  },
];
