import { useMediaQuery } from "react-responsive";
import Container from "../Container";
import Title from "../Title";
import { partnersList } from "./partnersList";

import { Background, Globe, Handshake, List, Wrapper } from "./styles";

export default function Partners() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1550px)",
  });
  return (
    <Background>
      <Container>
        <Wrapper>
          <Title color="var(--brand-color)">
            + de 200 empresas parceiras que estão de olho nos nossos devs:
          </Title>
          <List>
            {partnersList.map((partner) => (
              <img
                key={partner.name}
                src={partner.img}
                alt={`Logo da empresa ${partner.name}`}
                width={73}
                height={38}
              />
            ))}
          </List>
        </Wrapper>
      </Container>
      <Globe />
      {isDesktop && <Handshake />}
    </Background>
  );
}
