import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import CompletedSubscription from "../pages/CompletedSubscription";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/influ" element={<Home />} />
      <Route
        path="/completed_subscription"
        element={<CompletedSubscription />}
      />

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}
