import styled from "styled-components";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";
import Youtube from "../../assets/img/youtube-call.png";
import Blog from "../../assets/img/blog-call.png";
import Whats from "../../assets/img/whats-call.png";

type CtaProps = {
  action: string;
};

export const Container = styled.div`
  background-image: linear-gradient(180deg, #0a1e32 10%, #0a1e32 40%, #000 40%);
  color: #fff;
  padding-top: 2rem;

  @media (min-width: 1024px) {
    padding-top: 4rem;
  }
`;

export const Content = styled.div`
  width: 85%;
  margin: 0 auto;
  max-width: 1200px;

  h1 {
    margin: 1rem 0;
    margin-top: 2rem;
    font-size: 1.25rem;

    @media (min-width: 1024px) {
      font-size: 2rem;
    }
  }

  p.note {
    font-size: 1rem;
    line-height: 1.25rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #1666b5;

    @media (min-width: 1024px) {
      font-size: 1.25rem;
    }
  }

  p.alert {
    margin: 2rem 0;
    padding: 1rem 2rem;
    border: 1px solid;
    border-color: rgba(196, 196, 196, 1);
    border-radius: 5px;

    @media (min-width: 1024px) {
      margin: 4rem 0;
      padding: 2rem 4rem;
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .center {
    justify-content: center;
  }

  p.next-question {
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 2rem 0;
  }

  ul {
    padding-left: 2rem;

    li {
      margin-top: 0.5rem;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.tips {
    @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &.media {
    @media (min-width: 1160px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export const Tip = styled.div`
  width: 100%;
  min-height: 12rem;
  margin-top: 1rem;
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 1024px) {
    min-height: 22.5rem;
    max-width: 15rem;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-top: 5rem;
  }
  @media (min-width: 1220px) {
    min-height: 22.5rem;
    max-width: 18rem;
  }
  .paragraph {
    @media (min-width: 1024px) {
      order: 2;
    }
  }
  .image {
    width: 5rem;
    @media (min-width: 1024px) {
      width: 8rem;
      order: 1;
      margin-bottom: 1rem;
    }
  }
  h3 {
    font-size: 1.25rem;
    color: #0000ff;
    margin-bottom: 1rem;

    @media (min-width: 1024px) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 1rem 2rem;
      background-color: #0000ff;
      color: #fff;
    }
  }
  p {
    color: #2d2d2d;
    font-size: 0.8rem;
    line-height: 1rem;

    @media (min-width: 768px) {
      font-size: 0.875rem;
      line-height: 1.2;
    }
  }
`;

export const SocialContent = styled.div`
  padding-top: 2rem;

  div.container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 4rem;
  }

  h2 {
    color: #fff;
    margin: 1rem 0;
    margin-top: 2rem;
    font-size: 1.25rem;
    font-family: "Jetbrains Mono", sans-serif;

    @media (min-width: 1024px) {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }
`;

export const CTABox = styled.div<CtaProps>`
  width: 100%;
  max-width: 22rem;
  margin: 1rem 0;
  div.image {
    width: 100%;
    height: 7rem;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @media (min-width: 1024px) {
      height: 12rem;
    }
    &.whatsapp {
      background: linear-gradient(
          34.64deg,
          #000000 8.71%,
          rgba(0, 0, 0, 0) 48.77%
        ),
        url(${Whats});
    }
    &.youtube {
      background: linear-gradient(
          34.64deg,
          #000000 8.71%,
          rgba(0, 0, 0, 0) 48.77%
        ),
        url(${Youtube});
    }
    &.blog {
      background: linear-gradient(
          34.64deg,
          #000000 8.71%,
          rgba(0, 0, 0, 0) 48.77%
        ),
        url(${Blog});
    }
    span {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      font-size: 1.2rem;
      font-weight: bold;
      @media (min-width: 1024px) {
        bottom: 1.5rem;
        left: 2rem;
        font-size: 1.75rem;
      }
    }
  }
  div.cta {
    background-color: #0e2b47;
    padding: 1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: 1024px) {
      height: 13.5rem;
      padding: 1.5rem;
    }
    p {
      @media (min-width: 1024px) {
        font-size: 1.25rem;
        line-height: 2rem;
      }
    }

    a {
      display: block;
      margin-top: 1rem;
      font-size: 1.2rem;
      border-radius: 5px;
      text-decoration: none;
      text-transform: uppercase;
      padding: 1rem;
      text-align: center;
      color: #fff;
      font-weight: bold;
      transition: filter 0.3s;
      background-color: ${(props) =>
        props.action === "whats"
          ? "#15B74E"
          : props.action === "youtube"
          ? "#ED1A3B"
          : "#0000FF"};

      &:hover,
      &:focus {
        filter: brightness(0.8);
      }
      @media (min-width: 1024px) {
        padding: 1.5rem 2rem;
        font-size: 1.5rem;
      }
    }
  }
`;

export const Footer = styled.div`
  padding: 2rem 0;
  border-top: 1px solid #1f3b56;

  @media (min-width: 1024px) {
    padding: 3rem 0;
  }
  div.footer-content {
    width: 80%;
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  img {
    width: 9rem;
  }
  div.social-wrapper {
    margin: 2.5rem auto;
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (min-width: 1024px) {
      margin: 0;
      order: 2;
    }

    a:hover,
    a:focus {
      svg {
        color: #007aff;
      }
    }

    svg {
      color: #fff;
      width: 2rem;
      height: 2rem;
      transition: color 0.3s;

      @media (min-width: 1024px) {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  p {
    color: #777777;
    text-align: center;
  }
`;

export const CheckIcon = styled(FaCheckCircle)`
  color: lightgreen;
  font-size: 1.2rem;
  flex-shrink: 0;
  margin-right: 0.5rem;
`;

export const AlertIcon = styled(IoMdAlert)`
  color: #ffff00;
  font-size: 5rem;
  margin-right: 0.5rem;
  @media (min-width: 1024px) {
    font-size: 2rem;
  }
`;
