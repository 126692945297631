import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
  FaTiktok,
} from "react-icons/fa";
import Logo from "../../assets/img/logo.png";

import {
  Container,
  Content,
  CheckIcon,
  AlertIcon,
  Wrapper,
  SocialContent,
  CTABox,
  Footer,
} from "./styles";

export default function CompletedSubscription() {
  const navigate = useNavigate();

  useEffect(() => {
    const influencerId = sessionStorage.getItem("influencerId");
    const transactionId = sessionStorage.getItem("transactionId");
    const script = document.createElement("script");

    if (transactionId === undefined || influencerId === undefined) {
      navigate("/");
    }

    if (influencerId !== "no-tracking") {
      script.innerHTML = `SquidPixel.registerSell({order_id: "${transactionId}",});`;
      document.head.appendChild(script);
    }

    return () => {
      if (influencerId !== "no-tracking") {
        document.head.removeChild(script);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Container>
        <Content>
          <div>
            <img src={Logo} alt="Kenzie Academy" width="200" height="50" />
          </div>

          <h1>Muito bem, você deu o 1° passo para transformar sua vida!</h1>

          <p className="note flex">
            <CheckIcon /> Dentro de instantes você receberá um e-mail com as
            instruções para as próximas etapas do processo seletivo.
          </p>

          <p className="alert flex center">
            <AlertIcon /> *Caso não tenha encontrado o nosso e-mail, verifique
            na sua caixa de spam ou no seu lixo eletrônico.
          </p>
        </Content>

        <SocialContent>
          <div className="container">
            <h2>
              Já que chegou até aqui, temos algumas sugestões para você...
            </h2>

            <Wrapper className="media">
              <CTABox action={"whats"}>
                <div className="image whatsapp">
                  <span>Whatsapp</span>
                </div>
                <div className="cta">
                  <p>Quer tirar alguma dúvida? Então é só chamar</p>
                  <a
                    href="https://wa.me/5541992516267"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Fale conosco
                  </a>
                </div>
              </CTABox>

              <CTABox action={"youtube"}>
                <div className="image youtube">
                  <span>Youtube</span>
                </div>
                <div className="cta">
                  <p>Comece a se preparar com o conteúdo do nosso canal</p>
                  <a
                    href="https://www.youtube.com/KenzieAcademyBrasil?sub_confirmation=1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Inscreva-se
                  </a>
                </div>
              </CTABox>

              <CTABox action={"blog"}>
                <div className="image blog">
                  <span>Blog</span>
                </div>
                <div className="cta">
                  <p>Conheça o mercado de programação para inciantes</p>
                  <a
                    href="https://kenzie.com.br/blog"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ler mais
                  </a>
                </div>
              </CTABox>
            </Wrapper>
          </div>

          <Footer>
            <div className="footer-content">
              <div>
                <img src={Logo} alt="Kenzie Academy" width="126" height="31" />
              </div>
              <div className="social-wrapper">
                <a
                  href="https://www.facebook.com/kenzieacademybr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="https://www.instagram.com/kenzieacademybr/?hl=pt-br"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
                <a
                  href="https://www.linkedin.com/school/kenzie-brasil/mycompany/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedinIn />
                </a>
                <a
                  href="https://www.youtube.com/KenzieAcademyBrasil?sub_confirmation=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYoutube />
                </a>
                <a
                  href="https://vm.tiktok.com/ZMRB7gjTk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTiktok />
                </a>
              </div>
              <p>&copy; 2022 Kenzie Academy. All rights reserved.</p>
            </div>
          </Footer>
        </SocialContent>
      </Container>
    </>
  );
}
