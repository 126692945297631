import styled from "styled-components";
import hand from "../../assets/img/hand.png";
import target from "../../assets/img/target.png";
import redArrow from "../../assets/img/average-arrow.png";
import yellowArrow from "../../assets/img/comparative-arrow.png";
import targetChecked from "../../assets/img/target-checked.png";
import frame from "../../assets/img/frame.png";

export const Background = styled.div`
  position: relative;
  background-image: linear-gradient(
    to bottom,
    #0000ff 90%,
    #0000ff 90%,
    #d9d9d9 10%,
    #d9d9d9 10%
  );

  @media (min-width: 768px) {
    background-image: linear-gradient(
      to bottom,
      #0000ff 86%,
      #0000ff 86%,
      #d9d9d9 14%,
      #d9d9d9 14%
    );
  }

  @media (min-width: 1024px) {
    background-image: linear-gradient(
      to bottom,
      #0000ff 78%,
      #0000ff 78%,
      #d9d9d9 22%,
      #d9d9d9 22%
    );
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.875rem;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  border-radius: 15px;
  background: rgba(98, 98, 98, 0.35);
  box-shadow: 1px 4px 5px rgba(255, 255, 255, 0.5),
    inset 0px 1px 5px 1px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(20px);
  gap: 1rem;
  position: relative;
  z-index: 3;

  &:nth-child(2) {
    background: rgba(0, 0, 0, 0.5);

    li {
      font-weight: 700;
    }
  }

  h3 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.125rem;
    font-family: "Montserrat", sans-serif;
    color: var(--text-color-secondary);

    span {
      font-size: 0.875rem;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: 1rem;
    li {
      color: var(--text-color-secondary);
      list-style: disc;
    }
  }

  @media (min-width: 1024px) {
    width: 22.375rem;
    flex-grow: 1;
    padding: 1.5rem;
    gap: 2rem;

    svg {
      width: 30px;
      height: 30px;
    }

    h3 {
      text-align: center;
      justify-content: center;
      align-items: flex-start;
      font-size: 1.125rem;

      span {
        font-size: 1rem;
      }
    }

    &:nth-child(2),
    :nth-child(3) {
      h3 {
        margin-bottom: 1rem;
      }
    }

    ul {
      gap: 1.5rem;
      li {
        font-size: 1.125rem;
      }
    }
  }

  @media (min-width: 1400px) {
    padding: 2.5rem;
  }

  @media (min-width: 1600px) {
    h3 {
      font-size: 1.625rem;
    }
    &:nth-child(2),
    :nth-child(3) {
      h3 {
        margin-bottom: 2.1rem;
      }
    }
  }
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 0.938rem;
  background-color: var(--text-color-secondary);
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.35);
  border-radius: 15px;
  margin: 1.875rem auto 0;
  gap: 0.875rem;
  max-width: 25rem;

  p {
    font-family: "JetBrains Mono", sans-serif;
    font-size: 1.175rem;
    text-transform: uppercase;
    text-align: center;

    strong {
      font-weight: 800;
    }
  }

  div {
    display: flex;
    gap: 0.563rem;
  }
  @media (min-width: 415px) {
    p {
      font-size: 1.375rem;
      text-align: start;
    }
    div {
      img {
        width: 90px;
        height: 90px;
      }
    }
  }

  @media (min-width: 540px) {
    div {
      img {
        width: 110px;
        height: 110px;
      }
    }
  }

  @media (min-width: 1024px) {
    max-width: 69.688rem;
    padding: 2.375rem 6.875rem;
    gap: 1.25rem;
    p {
      font-size: 2.188rem;
    }

    div {
      img {
        width: 180px;
        height: 180px;
      }
    }
  }

  @media (min-width: 1300px) {
    div {
      img {
        width: 250px;
        height: 250px;
      }
    }
  }
`;

export const Hand = styled.div`
  background: url(${hand}) no-repeat;
  background-size: contain;
  width: 87px;
  height: 104px;
  position: absolute;
  top: -25px;
  right: 30px;

  @media (min-width: 1024px) {
    width: 106px;
    height: 127px;
    right: -25px;
  }

  @media (min-width: 1600px) {
    right: -15px;
    top: -10px;
  }
`;

export const Target = styled.div`
  background: url(${target}) no-repeat;
  background-size: contain;
  width: 55px;
  height: 55px;
  position: absolute;
  top: 65%;
  right: 7%;
  z-index: 1;

  &:nth-child(2) {
    width: 38px;
    height: 38px;
    top: 300px;
    left: 10px;
    z-index: 1;
  }

  @media (min-width: 1024px) {
    width: 71px;
    height: 71px;
    left: 60px;
    top: 300px;
  }

  @media (min-width: 1700px) {
    width: 71px;
    height: 71px;
    left: 140px;
  }
`;

export const RedArrow = styled.div`
  background: url(${redArrow}) no-repeat;
  background-size: contain;
  width: 355px;
  height: 47px;
  position: absolute;
  bottom: 20px;
  left: -240px;

  @media (min-width: 1600px) {
    left: -160px;
  }

  @media (min-width: 1920px) {
    left: 0;
  }
`;

export const YellowArrow = styled.div`
  background: url(${yellowArrow}) no-repeat;
  background-size: contain;
  width: 41px;
  height: 311px;
  position: absolute;
  top: 0;
  right: 30px;

  @media (min-width: 1600px) {
    right: 140px;
  }
`;

export const TargetChecked = styled.div`
  background: url(${targetChecked}) no-repeat;
  background-size: contain;
  width: 85px;
  height: 85px;
  position: absolute;
  bottom: 100px;
  right: 50px;

  @media (min-width: 1920px) {
    right: 220px;
  }
`;

export const Frame = styled.div`
  background: url(${frame}) no-repeat;
  background-size: contain;
  width: 35px;
  height: 399px;
  position: absolute;
  bottom: 0;
  right: 0;

  @media (min-width: 1024px) {
    width: 59px;
    height: 768px;
  }
`;
