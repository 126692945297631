import { useMediaQuery } from "react-responsive";
import Container from "../Container";
import Title from "../Title";
import ActiveCampaignForm from "../ActiveCampaignForm";
import { ReactComponent as Percentage } from "../../assets/img/financing-works-percentage.svg";
import { ReactComponent as Calendar } from "../../assets/img/financing-works-calendar.svg";
import { ReactComponent as Wallet } from "../../assets/img/financing-works-wallet.svg";
import { ReactComponent as Sign } from "../../assets/img/financing-works-sign.svg";

import {
  Announcement,
  Background,
  Coin,
  Content,
  Experience,
  FinancingWorks,
  Flex,
  FormTitle,
  SpecialConditions,
  Wrapper,
} from "./styles";

interface Props {
  v2?: boolean;
  refProp?: React.RefObject<HTMLDivElement>;
}

export default function InvestimentConditions({ v2 = false, refProp }: Props) {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  const isDesktopLarge = useMediaQuery({
    query: "(min-width: 1400px)",
  });
  return (
    <Background>
      <Container>
        <Wrapper>
          <Title color="var(--text-color-secondary)">
            condições de investimento:
          </Title>
          <p className="description">
            Em apenas um ano você se tornará um profissional de programação,
            investindo da maneira que melhor se encaixa em seu orçamento.
            Conheça as opções:
          </p>

          <Content>
            <Flex>
              <Announcement>
                <h3>À vista</h3>
                <p>
                  Oportunidade de programar sua evolução com um desconto
                  camarada:
                </p>
                <p>
                  de <span className="sign">R$</span>{" "}
                  <span className="before">22.000</span> <br />
                  por <span className="sign big">R$</span>{" "}
                  <span className="after">17.600</span>
                </p>
              </Announcement>

              {v2 && (
                <Announcement>
                  <h3>Parcelamento</h3>
                  <p>
                    Opção de pagamento parcelado enquanto você estuda, com{" "}
                    <strong>10% OFF:</strong>
                  </p>
                  <p>
                    de <span className="installment">12x de</span>{" "}
                    <span className="sign">R$</span>{" "}
                    <span className="before">2.000</span>
                  </p>
                  <p>
                    por <span className="installment">12x de </span>
                    <span className="sign big">R$ </span>
                    <span className="after">1.800</span>
                  </p>
                  <p className="italic">
                    Pagamento da primeira parcela até 20 dias após o início das
                    aulas.
                  </p>
                </Announcement>
              )}

              {!v2 && !isDesktop && (
                <Announcement>
                  <h3>Parcelamento</h3>
                  <p>
                    Opção de pagamento parcelado enquanto você estuda, com{" "}
                    <strong>10% OFF:</strong>
                  </p>
                  <p>
                    de <span className="installment">12x de</span>{" "}
                    <span className="sign">R$</span>{" "}
                    <span className="before">2.000</span>
                  </p>
                  <p>
                    por <span className="installment">12x de </span>
                    <span className="sign big">R$ </span>
                    <span className="after">1.800</span>
                  </p>
                  <p className="italic">
                    Pagamento da primeira parcela até 20 dias após o início das
                    aulas.
                  </p>
                </Announcement>
              )}

              <Announcement>
                <h3>
                  {isDesktopLarge
                    ? "financiamento com carência"
                    : "financiamento"}
                </h3>
                <p>
                  Comece a estudar agora e pague somente{" "}
                  <strong>5% de entrada</strong> do valor do curso. Pague a
                  próxima parcela só quando se formar e{" "}
                  <strong>parcele o restante em até 36x*.</strong>
                </p>

                <p className="italic">*Valor final sujeito a alterações</p>
              </Announcement>
            </Flex>

            <Flex>
              {v2 ? (
                <>
                  {isDesktop && (
                    <>
                      <div ref={refProp}>
                        <FormTitle>
                          faça sua inscrição agora e inicie sua formação com
                          desconto especial!
                        </FormTitle>
                      </div>
                      <ActiveCampaignForm
                        color="#ee312a"
                        noteColor="#fff"
                        secondarySchema
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  {isDesktop && (
                    <Announcement>
                      <h3>Parcelamento</h3>
                      <p>
                        Opção de pagamento parcelado enquanto você estuda, com{" "}
                        <strong>10% OFF:</strong>
                      </p>
                      <p>
                        de <span className="installment">12x de</span>{" "}
                        <span className="sign">R$</span>{" "}
                        <span className="before">2.000</span>
                      </p>
                      <p>
                        por <span className="installment">12x de </span>
                        <span className="sign big">R$ </span>
                        <span className="after">1.800</span>
                      </p>
                      <p className="italic">
                        Pagamento da primeira parcela até 20 dias após o início
                        das aulas.
                      </p>
                    </Announcement>
                  )}
                </>
              )}
            </Flex>
          </Content>

          <FinancingWorks>
            <h4>Como funciona? A gente te explica!</h4>
            <ul>
              <li>
                <Percentage />
                Pagamento de entrada de 5% do valor
              </li>
              <li>
                <Calendar />
                Pagamento da primeira parcela após 12 meses
              </li>
              <li>
                <Wallet />
                Parcelamento em até 36x por boleto
              </li>
              <li>
                <Sign />
                Valor financiado: R$24.000,00
              </li>
            </ul>
          </FinancingWorks>

          {v2 && !isDesktop && (
            <>
              <div ref={refProp}>
                <FormTitle>
                  faça sua inscrição agora e inicie sua formação com desconto
                  especial!
                </FormTitle>
              </div>
              <ActiveCampaignForm
                color="#ee312a"
                noteColor="#fff"
                secondarySchema
              />
            </>
          )}

          <Experience>
            <svg width="72" height="72" fill="none" viewBox="0 0 72 72">
              <path
                fill="#F8C808"
                d="M32.362 45.51c-.6 0-1.17-.24-1.59-.66l-7.26-7.26c-.87-.87-.87-2.31 0-3.18.87-.87 2.31-.87 3.18 0l5.67 5.67 12.9-12.9c.87-.87 2.31-.87 3.18 0 .87.87.87 2.31 0 3.18l-14.49 14.49c-.42.42-.99.66-1.59.66z"
              />
              <path
                fill="#F8C808"
                d="M36 68.25c-1.89 0-3.78-.63-5.25-1.89l-4.74-4.08c-.48-.42-1.68-.84-2.31-.84h-5.16a8.04 8.04 0 01-8.04-8.04v-5.13c0-.63-.42-1.8-.84-2.28l-4.05-4.77c-2.46-2.91-2.46-7.5 0-10.41l4.05-4.77c.42-.48.84-1.65.84-2.28V18.6c0-4.44 3.6-8.04 8.04-8.04h5.19c.63 0 1.83-.45 2.31-.84l4.74-4.08c2.94-2.52 7.53-2.52 10.47 0l4.74 4.08c.48.42 1.68.84 2.31.84h5.1c4.44 0 8.04 3.6 8.04 8.04v5.1c0 .63.45 1.83.87 2.31l4.08 4.74c2.52 2.94 2.52 7.53 0 10.47l-4.08 4.74c-.42.48-.87 1.68-.87 2.31v5.1c0 4.44-3.6 8.04-8.04 8.04h-5.1c-.63 0-1.83.45-2.31.84l-4.74 4.08c-1.47 1.29-3.36 1.92-5.25 1.92zM18.54 15.06c-1.95 0-3.54 1.59-3.54 3.54v5.13c0 1.71-.81 3.9-1.92 5.19l-4.05 4.77c-1.05 1.23-1.05 3.36 0 4.59l4.05 4.77c1.11 1.32 1.92 3.48 1.92 5.19v5.13c0 1.95 1.59 3.54 3.54 3.54h5.19c1.74 0 3.93.81 5.25 1.95l4.74 4.08c1.23 1.05 3.39 1.05 4.62 0l4.74-4.08c1.32-1.11 3.51-1.95 5.25-1.95h5.1c1.95 0 3.54-1.59 3.54-3.54v-5.1c0-1.74.81-3.93 1.95-5.25L63 38.28c1.05-1.23 1.05-3.39 0-4.62l-4.08-4.74c-1.14-1.32-1.95-3.51-1.95-5.25V18.6c0-1.95-1.59-3.54-3.54-3.54h-5.1c-1.74 0-3.93-.81-5.25-1.95l-4.74-4.08c-1.23-1.05-3.39-1.05-4.62 0l-4.74 4.11c-1.32 1.11-3.54 1.92-5.25 1.92h-5.19z"
              />
            </svg>
            <p>
              Experimente a formação Kenzie por 30 dias e desista sem nenhum
              custo.
            </p>
          </Experience>
        </Wrapper>
      </Container>
      <Coin />
      {!isDesktop && (
        <>
          <SpecialConditions />
          <SpecialConditions />
        </>
      )}
    </Background>
  );
}
