import { useMediaQuery } from "react-responsive";
import { useEffect, useRef, useState } from "react";
import AverageWage from "../../components/AverageWage";
import Comparative from "../../components/Comparative";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Hero from "../../components/Hero";
import Testimonials from "../../components/Testimonials";
import IndicatedCourse from "../../components/IndicatedCourse";
import Partners from "../../components/Partners";
import InvestimentConditions from "../../components/InvestimentConditions";
import LearningPath from "../../components/LearningPath";
import FAQ from "../../components/FAQ";
import WhatsApp from "../../components/WhatsApp";
import PopUp from "../../components/PopUp";

import {
  Background,
  Chess,
  Coin,
  Frame,
  Grid,
  Megaphone,
  MegaphoneBlur,
  SecondCoin,
  Smiles,
} from "./styles";

export default function Home() {
  const [openModal, setOpenModal] = useState(false);
  let userLeft = false;
  const ref = useRef<HTMLDivElement>(null);

  function scrollToRef() {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "auto" });
    }
  }

  const isMobile = useMediaQuery({
    query: "(min-width: 400px)",
  });
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const isDesktopLarge = useMediaQuery({
    query: "(min-width: 1500px)",
  });

  function handleLeave() {
    if (!userLeft) {
      userLeft = true;
      setTimeout(() => {
        setOpenModal(true);
      }, 200);
    }
  }

  useEffect(() => {
    document.addEventListener("mouseleave", handleLeave);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header v2 onClick={scrollToRef} />
      <main>
        <Hero onClick={scrollToRef} />
        <Background>
          <AverageWage />
          <IndicatedCourse />
          <Testimonials />
          <Comparative />

          {isMobile && (
            <>
              <Grid />
              <Smiles />
            </>
          )}
          {isDesktop && <Chess />}
          {isDesktopLarge && <Megaphone />}

          <Frame />
        </Background>
        <Background>
          <Partners />
          <LearningPath />
          <InvestimentConditions v2 refProp={ref} />
          {isMobile && <Coin />}
          {isDesktop && (
            <>
              <MegaphoneBlur />
              <SecondCoin />
            </>
          )}
        </Background>
        <FAQ />
      </main>
      <Footer />
      <WhatsApp />
      <PopUp open={openModal} setOpenModal={setOpenModal} />
    </>
  );
}
