import { useState } from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

import {
  Container,
  TestimonialHeader,
  TestimonialContent,
  TitleContent,
} from "./styles";

interface Props {
  student: {
    name: string;
    genre: string;
    role: string;
    company?: string;
    testimonial?: string[];
    image?: string;
    video?: string;
    videoId?: number;
    videoHeight?: number;
  };
}

export default function TestimonialCard({ student }: Props) {
  const [mask, setMask] = useState(true);

  function removeMask() {
    setMask(false);
  }

  if (student.video) {
    const ytbMask = document.getElementsByClassName("yt-lite");
    setTimeout(() => {
      ytbMask[student.videoId || 0]?.addEventListener("click", removeMask);
    }, 100);
  }

  return (
    <>
      {student.video ? (
        <Container student={student}>
          <LiteYouTubeEmbed
            id={student.video}
            title={`Video de depoimento - ${student.name}`}
            poster="maxresdefault"
            params="rel=0"
          />
          <TitleContent student={student} active={mask}>
            <h3>{student.name}</h3>
            <span>{student.role}</span>
          </TitleContent>
        </Container>
      ) : (
        <Container student={student}>
          <TestimonialHeader>
            <img
              src={student.image}
              width="43"
              height="43"
              alt={`Foto ${
                student.genre === "masculino" ? "do aluno" : "da aluna"
              } ${student.name}`}
            />
            <TitleContent student={student} active={mask}>
              <h3>{student.name}</h3>
              <span> {`${student.role}`}</span>
            </TitleContent>
          </TestimonialHeader>
          {student.testimonial?.map((item) => (
            <TestimonialContent key={item}>{item}</TestimonialContent>
          ))}
        </Container>
      )}
    </>
  );
}
